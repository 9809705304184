import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit
} from '@angular/core';
import { TagOwnershipType } from '@ui/shared/models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, UpperCasePipe } from '@angular/common';
import { TAG_CONFIGS, TagColor, TagConfig } from './tag-config';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgbTooltip, TranslateModule, UpperCasePipe]
})
export class TagComponent implements OnInit {
  private translate = inject(TranslateService);

  readonly name = input<string>(undefined);
  readonly isSystemTag = input<boolean>(undefined);
  readonly tagType = input<TagOwnershipType>(undefined);
  readonly disableTooltip = input<boolean>(undefined);
  readonly nowrap = input<boolean>(undefined);

  public truncate = false;
  public tooltip = '';
  protected TRUNCATE_LENGTH = 15;

  ngOnInit(): void {
    this.truncate =
      (this.name() || this.tagConfig?.label)?.length > this.TRUNCATE_LENGTH;
    this.manageTooltip();
  }

  private manageTooltip(): void {
    this.tooltip = this.truncate
      ? this.name() || this.tagConfig?.label
      : this.tagConfig?.tooltip
        ? String(this.translate.instant(this.tagConfig.tooltip))
        : '';
  }

  public get tagConfig(): TagConfig {
    let config;
    const tagType = this.tagType();
    if (this.isSystemTag()) {
      config = this.name();
    } else if (tagType === TagOwnershipType.PERSONAL) {
      config = TagColor.PERSONAL;
    } else if (tagType === TagOwnershipType.DYNAMIC) {
      this.name().startsWith('PROJEKT-')
        ? (config = TagColor.PROJECT)
        : (config = TagColor.DYNAMIC);
    } else {
      config = TagColor.SHARED;
    }
    return TAG_CONFIGS[config];
  }
}

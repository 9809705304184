import { Component, forwardRef, input, output } from '@angular/core';

import {
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';

import moment from 'moment';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SelfDisclosureQuestion } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass } from '@angular/common';
import { BaseControl } from '../../../components/legacy/form/controls/base-control';
import { FormFieldLabelComponent } from '../../../components/legacy/form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../../components/legacy/form/form-field/form-field.component';
import { AppInputDirective } from '../../../components/legacy/form/controls/input';
import { DateComponent } from '../../../components/legacy/form/controls/date';

@Component({
  selector: 'app-self-disclosure-child',
  templateUrl: './self-disclosure-child.component.html',
  styleUrls: ['./self-disclosure-child.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelfDisclosureChildComponent),
      multi: true
    }
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    SvgIconComponent,
    TranslateModule,
    FormFieldLabelComponent,
    FormFieldComponent,
    AppInputDirective,
    DateComponent
  ]
})
export class SelfDisclosureChildComponent extends BaseControl<any> {
  readonly selfDisclosureQuestion = input<SelfDisclosureQuestion>(undefined);
  readonly title = input<string, string>(undefined, {
    transform: v => v || this.selfDisclosureQuestion().title
  });
  readonly form = input<FormGroup>(undefined);
  readonly showRemove = input(false);

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  readonly onRemove = output<void>();

  public maxDateStruct: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  public startDateStruct: NgbDateStruct = {
    year: 1990,
    month: 1,
    day: 1
  };

  public minDateStruct: NgbDateStruct = {
    year: 1900,
    month: 1,
    day: 1
  };

  public writeValue(value: any) {
    this.value = value ? value : [];
  }

  public get getAnswerFormControl() {
    return this.form().get('answer') as FormGroup;
  }

  public removeHandler() {
    this.onRemove.emit();
  }
}

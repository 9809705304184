import {
  ApplicationRef,
  ComponentRef,
  inject,
  Injectable,
  signal,
  ViewContainerRef
} from '@angular/core';
import { timer } from 'rxjs';
import { ToastOptions } from './model/toast-options';
import { Toast } from './model/toast.model';
import { ToastContainerComponent } from './toast-container.component';

@Injectable({ providedIn: 'root' })
export class ToastService {
  #applicationRef = inject(ApplicationRef);
  #rootContainerRef: ViewContainerRef;
  #toastContainer: ComponentRef<ToastContainerComponent>;
  #toastIndex = 0;

  readonly toasts = signal<Toast[]>([]);

  public setRootViewContainerRef(ref: ViewContainerRef) {
    this.#rootContainerRef = ref;
  }

  private show(toast: Toast) {
    if (!this.#toastContainer) {
      if (!this.#rootContainerRef) {
        try {
          this.#rootContainerRef = this.#applicationRef.components[0].instance
            .viewContainerRef as ViewContainerRef;
        } catch (e) {
          throw new Error('Inject ViewContainerRef into root component');
        }
      }

      this.#toastContainer = this.#rootContainerRef.createComponent(
        ToastContainerComponent
      );

      this.#toastContainer.instance.removeToast.subscribe(() =>
        this.clearToast()
      );
      this.#toastContainer.instance.toastClicked.subscribe((t: Toast) =>
        this.onToastClick(t)
      );
    }

    this.addToast(toast);
  }

  private addToast(toast: Toast) {
    toast.id = ++this.#toastIndex;
    this.toasts.update(toasts => [...toasts, toast]);
    timer(toast.duration).subscribe(() => this.clearToast(toast));
  }

  private clearToast(toast: Toast = this.toasts()[0]) {
    this.toasts.update(toasts => toasts.filter(t => t.id !== toast.id));
    if (this.toasts().length === 0) this.destroyToastContainer();
  }

  private destroyToastContainer() {
    timer(500).subscribe(() => {
      this.#toastContainer.destroy();
      this.#toastContainer = null;
    });
  }

  private onToastClick(toast: Toast) {
    if (toast.dismissOnClick) {
      this.clearToast(toast);
    }
  }

  public success(message: string, options: ToastOptions = {}) {
    const toast = new Toast('success', message, options);
    this.show(toast);
  }

  public info(message: string, options: ToastOptions = {}) {
    const toast = new Toast('info', message, options);
    this.show(toast);
  }

  public error(message: string, options: ToastOptions = {}) {
    const toast = new Toast('error', message, options);
    this.show(toast);
  }

  public warning(message: string, options: ToastOptions = {}) {
    const toast = new Toast('warning', message, options);
    this.show(toast);
  }
}

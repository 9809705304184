import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-list-navigation-v2',
  templateUrl: './list-navigation-v2.component.html',
  styleUrls: ['./list-navigation-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgbTooltip, TranslateModule]
})
export class ListNavigationV2Component {
  readonly navConfig = input<any[]>(undefined);
  readonly totals = input<number[]>(undefined);
  readonly selectedNav = input<string>(undefined);
  readonly disabled = input<boolean>(undefined);
  readonly medium = input<boolean>(undefined);
  readonly navSelect = output<any>();

  selectNav(tab: any) {
    if (this.disabled() || tab.disabled) return;

    this.navSelect.emit(tab.value);
  }
}

import { inject, Injectable } from '@angular/core';
import { NotificationService } from '../notification';

@Injectable()
export class ClipboardService {
  private notificationService = inject(NotificationService);

  public selectCopyInput(input: HTMLInputElement | HTMLTextAreaElement) {
    if (!input) return;

    input.select();
    this.copyToClipboard(input.value);
  }

  public copyToClipboard(text: string) {
    void navigator.clipboard
      .writeText(text)
      .then(() =>
        this.notificationService.showInfoToast('general.text_copied_l')
      );
  }
}

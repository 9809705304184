import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  input,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { FtpType, PortalConfig, PortalCredential } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';

import { BasePortalCredentialControl } from '../../controls';

import { CredentialValidatorService } from '../../services';
import { FormFieldLabelComponent } from '../../../components/legacy/form/form-field/form-field-label/form-field-label.component';
import { AppInputDirective } from '../../../components/legacy/form/controls/input';
import { FormFieldComponent } from '../../../components/legacy/form/form-field/form-field.component';
import { ButtonComponent } from '../../../components/atoms/button';
import { SelectComponent } from '../../../components/legacy/form/controls/select/select.component';
import { LoadingSpinnerComponent } from '../../../components/legacy/loading-spinner/loading-spinner.component';

@UntilDestroy()
@Component({
  selector: 'app-homepage-module-credential-details',
  templateUrl: './homepage-module-credential-details.component.html',
  styleUrls: ['./homepage-module-credential-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HomepageModuleCredentialDetailsComponent),
      multi: true
    }
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormFieldLabelComponent,
    AppInputDirective,
    FormFieldComponent,
    ButtonComponent,
    SelectComponent,
    LoadingSpinnerComponent
  ]
})
export class HomepageModuleCredentialDetailsComponent
  extends BasePortalCredentialControl
  implements OnInit
{
  private fb = inject(FormBuilder);
  private cdr = inject(ChangeDetectorRef);
  private credentialValidatorService = inject(CredentialValidatorService);

  public ftpTypes = [
    { name: FtpType.FTP, value: FtpType.FTP },
    { name: FtpType.SFTP, value: FtpType.SFTP },
    { name: FtpType.FTPS, value: FtpType.FTPS }
  ];

  public readonly portalConfig = input<PortalConfig>(undefined);
  public readonly credential = input<PortalCredential>(undefined);

  public showFtpSettings = false;
  public checkingFtp = false;
  public validFtpConnection = false;
  public invalidFtpConnection = false;

  public get hostControl() {
    return this.propertiesControl.get('host');
  }

  public get portControl() {
    return this.propertiesControl.get('port');
  }

  constructor() {
    super();

    this.form = this.fb.group({
      name: ['', Validators.required],
      properties: this.fb.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
        host: [''],
        port: [''],
        type: [FtpType.FTP]
      })
    });
  }

  public ngOnInit() {
    const credentialValue = this.credential();
    if (credentialValue) {
      this.form.patchValue(credentialValue);
    }

    this.form.valueChanges.subscribe(credential => {
      this.value = { ...credential };

      const hasFtpSetting =
        this.value.properties.host || this.value.properties.port;

      if (!this.showFtpSettings || !hasFtpSetting) {
        this.value.properties = {
          username: credential.properties.username,
          password: credential.properties.password
        };
      }

      this.onChange(this.value);
      this.onTouch();
    });

    this.propertiesControl.valueChanges.subscribe(() => {
      this.validFtpConnection = false;
      this.invalidFtpConnection = false;
    });

    this.credentialValidatorService.validationError$
      .pipe(
        filter(portal => !!portal),
        untilDestroyed(this)
      )
      .subscribe(portal =>
        this.setCredentialValidationError(
          this.portalConfig().validationControl || 'password',
          portal === this.credential().portal
        )
      );
  }

  public toggleFtpSettings() {
    this.showFtpSettings = !this.showFtpSettings;

    if (this.showFtpSettings) {
      this.hostControl.setValidators(Validators.required);
      this.portControl.setValidators(Validators.required);
    } else {
      this.hostControl.clearValidators();
      this.portControl.clearValidators();
    }

    this.form.updateValueAndValidity();
  }

  public checkFtpConnection() {
    this.checkingFtp = true;
    this.invalidFtpConnection = false;

    this.credentialValidatorService
      .validateFtpConnection(this.credential())
      .pipe(
        catchError(() => {
          this.validFtpConnection = false;
          this.checkingFtp = false;
          this.invalidFtpConnection = true;

          this.cdr.detectChanges();

          return of(false);
        })
      )
      .subscribe(valid => {
        this.validFtpConnection = valid;
        this.invalidFtpConnection = !valid;
        this.checkingFtp = false;

        this.cdr.detectChanges();
      });
  }
}

import { ComponentRef, inject, Injectable, input, Type } from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';

import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { FreshworksService } from '../../../infrastructure/browser/freshworks';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { RejectionDialogComponent } from './rejection-dialog/rejection-dialog.component';

export interface ModalOptions<T> extends NgbModalOptions {
  data?: ComponentInputs<T>;
}

export class ModalRef<_T, R = any> extends NgbModalRef {
  onClose: () => Observable<R>;
  onDismiss: () => Observable<any>;
}

type ComponentInputs<T> = keyof {
  [K in keyof T as T[K] extends ReturnType<typeof input> ? K : never]: T[K];
} extends never
  ? never
  : {
      [K in keyof T as T[K] extends ReturnType<typeof input>
        ? K
        : never]?: T[K] extends ReturnType<typeof input<infer U>> ? U : never;
    };

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private ngbModal = inject(NgbModal);
  private freshworksService = inject(FreshworksService, { optional: true });

  // Type represents a type that a Component or other object is instances of.
  // Ref: https://angular.dev/api/core/Type?tab=description
  open<T, R = any>(content: Type<T>, options: ModalOptions<T> = {}) {
    const freshworksWidgetsVisibilityOnOpening =
      this.freshworksService?.getWidgetsVisibility();

    // hide Freshworks widgets if they are visible at the moment:
    if (freshworksWidgetsVisibilityOnOpening)
      this.freshworksService?.hideWidgets();

    const onCloseSubject = new Subject<R>();
    const onDismissSubject = new Subject();

    const modalRef = this.ngbModal.open(content, options) as ModalRef<T>;
    /**
     * TODO: This is a hack to get the ComponentRef because NgbModalRef does not expose it.
     * See https://github.com/ng-bootstrap/ng-bootstrap/issues/4688
     */
    const componentRef: ComponentRef<T> = modalRef['_contentRef'].componentRef;

    if (modalRef && componentRef && options.data) {
      for (const property in options.data) {
        componentRef.setInput(property, options.data[property]);
      }
    }

    modalRef.onClose = () => onCloseSubject.asObservable().pipe(take(1));
    modalRef.onDismiss = () => onDismissSubject.asObservable().pipe(take(1));

    modalRef.result
      .then((result: R) => {
        onCloseSubject.next(result);
        onCloseSubject.complete();
      })
      .catch((reason: any) => {
        onDismissSubject.next(reason);
        onDismissSubject.complete();
      })
      .finally(() => {
        // restore visibility of Freshworks widgets if they were visible when opening the modal:
        if (freshworksWidgetsVisibilityOnOpening)
          this.freshworksService?.showWidgets();
      });

    return modalRef;
  }

  /***
   * Fix for Angular Bug related to creating dynamic views in lifecycle hooks.
   * Doing that causes ExpressionHasBeenChangedAfterItWasChecked errors.
   * Using setTimeout is simple and acceptable workaround for this.
   * See https://github.com/angular/angular/issues/15634 for more details.
   * Use it only in lifecycle hooks (ngOnInit, ngOnChanges and so on)
   * For every normal case, use normal .open() method
   */
  promisifyOpen<T, R = any>(
    content: any,
    options: ModalOptions<T> = {}
  ): Promise<ModalRef<T, R>> {
    return new Promise(resolve =>
      setTimeout(() => resolve(this.open(content, options)))
    );
  }

  openConfirmation(options: ModalOptions<ConfirmationDialogComponent> = {}) {
    return this.open(ConfirmationDialogComponent, options);
  }

  openAcknowledgement(options: ModalOptions<ConfirmationDialogComponent> = {}) {
    return this.openConfirmation({
      ...options,
      data: {
        ...options.data,
        acknowledge: true
      }
    });
  }

  openRejection(options: ModalOptions<RejectionDialogComponent> = {}) {
    return this.open(RejectionDialogComponent, options);
  }
}

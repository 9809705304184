<input
  class="form-control"
  appInput
  #phoneInput
  [(ngModel)]="value"
  [id]="id()"
  [required]="required()"
  [disabled]="disabled"
  [readonly]="readOnly()"
  (input)="onInput()"
  (focusout)="onFocusOut()"
/>

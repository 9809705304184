import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  contentChildren,
  ElementRef,
  input,
  output,
  TemplateRef,
  untracked,
  viewChild
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { DataTableCellDirective } from '../data-table/data-table-cell/data-table-cell.directive';
import {
  DataTableColumn,
  DataTableDefaultConfig
} from '../data-table/data-table.model';
import { LoadingSpinnerComponent } from '../../legacy/loading-spinner/loading-spinner.component';
import { InfiniteScrollComponent } from '../../atoms/infinite-scroll/infinite-scroll.component';

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InfiniteScrollComponent,
    NgTemplateOutlet,
    NgClass,
    LoadingSpinnerComponent,
    TranslateModule
  ]
})
export class BasicTableComponent implements AfterContentInit {
  readonly headColumns = input<Partial<DataTableColumn>[]>(undefined);
  readonly data = input<unknown[]>();
  readonly isLoading = input<boolean>(undefined);
  readonly borderedTable = input(true);
  readonly hoveredTable = input(true);
  readonly smallTable = input<boolean>(undefined);
  readonly responsiveTable = input<boolean>(undefined);
  readonly stripedTable = input<boolean>(undefined);
  readonly darkTable = input<boolean>(undefined);
  readonly darkHeader = input<boolean>(undefined);
  readonly lightHeader = input<boolean>(undefined);
  readonly showIndex = input<boolean>(undefined);
  readonly scrollable = input<boolean>(undefined);
  readonly hasNextPage = input<boolean>(undefined);
  readonly noDataMessage = input(DataTableDefaultConfig.noDataMessage);
  readonly uniqueIdentifier = input('id');
  readonly maxScrollableHeight = input('450px');
  readonly scrollChange = output();
  readonly templates = contentChildren(DataTableCellDirective);
  readonly infiniteScrollRef = viewChild(InfiniteScrollComponent, {
    read: ElementRef
  });

  readonly dataEffect = () => {
    const data = this.data();

    untracked(() => {
      if (this.scrollable()) this.updateViewToScroll(data);
    });
  };

  public cellTemplatesMap: { [key: string]: TemplateRef<HTMLDivElement> };

  public ngAfterContentInit() {
    this.cellTemplatesMap = this.templates().reduce((acc, cur) => {
      acc[cur.name()] = cur.template;
      return acc;
    }, {});
  }

  public onScrollChange() {
    if (this.hasNextPage()) {
      this.scrollChange.emit();
    }
  }

  private updateViewToScroll(data: unknown[]) {
    const isNewData = !data
      ?.map(d => d[this.uniqueIdentifier()] as unknown)
      .includes(this.data()?.[0]?.[this.uniqueIdentifier()]);
    if (isNewData) this.infiniteScrollRef().nativeElement.scrollTop = 0;
  }
}

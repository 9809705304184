<div class="label-list-wrapper">
  @for (
    tag of truncateLabels()
      ? (tags() | slice: 0 : amountBeforeTruncation())
      : tags();
    track tag
  ) {
    <app-wbs-tag
      class="labels-tag me-2 align-self-center"
      [tag]="tag"
      [enableUpperCase]="enableUpperCase()"
    >
    </app-wbs-tag>
  }
  @if (truncateLabels() && tags()?.length > amountBeforeTruncation()) {
    <app-context-menu class="align-self-center me-2" [container]="'body'">
      <app-badge menu-button class="c-pointer">
        ... {{ tags()?.length - amountBeforeTruncation() }}
      </app-badge>
      <!-- style attribute is used because the tooltip is applied to the body
      and class styles defined in this component will not affect it -->
      <div style="overflow-y: scroll; max-height: 275px" menu-content>
        @for (
          tag of tags() | slice: amountBeforeTruncation() : tags()?.length;
          track tag
        ) {
          <app-context-menu-item>
            <app-wbs-tag
              class="labels-tag me-2 align-self-center"
              [tag]="tag"
              [enableUpperCase]="enableUpperCase()"
            >
            </app-wbs-tag>
          </app-context-menu-item>
        }
      </div>
    </app-context-menu>
  }
</div>

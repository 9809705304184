import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  inject,
  Input,
  input,
  model,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';

import { NgControl } from '@angular/forms';

import { Subject } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { coerceBooleanProperty } from '../../../../../utils';

const SUPPORTED_INPUT_TYPES = [
  'text',
  'password',
  'email',
  'number',
  'checkbox',
  'range',
  'date',
  'time'
];

const getUniqueId = (() => {
  let nextUniqueId = 0;
  return () => `app-input-${++nextUniqueId}`;
})();

@UntilDestroy()
@Directive({
  selector:
    'input[appInput], textarea[appInput], app-color-picker[appInput], app-international-phone[appInput], app-single-select-dropdown[appInput], app-dropdown-multiselect[appInput]',
  exportAs: 'appInput',
  providers: [
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => AppInputDirective)
    }
  ],
  host: {
    '[attr.placeholder]': 'placeholder() || null',
    '[attr.required]': 'required()',
    '[attr.readonly]': 'readonly() || null',
    '[attr.id]': 'id()'
  }
})
export class AppInputDirective
  implements AppFormFieldControl<any>, OnInit, OnChanges, OnDestroy
{
  element = inject(ElementRef);
  ngControl = inject(NgControl, { optional: true, self: true });

  public counter = model(0);
  private _type = 'text';
  private _maxValue = 0;
  private _allowExponential = false;

  stateChanges = new Subject<void>();

  focused = false;

  readonly placeholder = input('');

  readonly required = input<boolean, boolean>(false, {
    transform: value => coerceBooleanProperty(value)
  });

  readonly readonly = input<boolean, boolean>(false, {
    transform: value => coerceBooleanProperty(value)
  });

  get value() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.ngControl?.value;
  }

  get name() {
    return String(this.ngControl?.name);
  }

  @Input()
  get type() {
    return this._type;
  }

  set type(value: string) {
    this._type = value || 'text';
    if (
      this.element.nativeElement.type === 'password' ||
      this.element.nativeElement.type === 'text'
    ) {
      this.element.nativeElement.type = value || 'text';
    }
    this.validateType();
  }

  @Input()
  get maxValue() {
    return this._maxValue;
  }

  set maxValue(value: number) {
    this.counter.set(value || 0);
    this._maxValue = value || 0;
  }

  readonly count = input<boolean, boolean>(undefined, {
    transform: v => coerceBooleanProperty(v)
  });

  @Input()
  get allowExponential() {
    return this._allowExponential;
  }

  set allowExponential(value: boolean) {
    this._allowExponential = coerceBooleanProperty(value);
  }

  readonly id = input<string>(getUniqueId());

  get touched() {
    return this.ngControl?.touched;
  }

  get errors() {
    return this.ngControl?.errors;
  }

  get min() {
    return (this.element.nativeElement as HTMLInputElement).min;
  }

  get nativeValue() {
    return (this.element.nativeElement as HTMLInputElement).value;
  }

  ngOnInit() {
    this.ngControl?.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
    if (this.count()) {
      (this.element.nativeElement as HTMLElement).setAttribute(
        'maxlength',
        `${this.maxValue}`
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(changes).forEach(key => {
      if (changes[key].currentValue !== changes[key].previousValue) {
        this.stateChanges.next();
      }
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  hasState(state: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.ngControl[state];
  }

  private validateType() {
    if (!SUPPORTED_INPUT_TYPES.includes(this.type)) {
      throw Error(`${this.type} input is not supported`);
    }
  }

  @HostListener('focus')
  onFocus() {
    if (!this.readonly()) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  @HostListener('blur')
  onBlur() {
    this.focused = false;
    this.stateChanges.next();
  }

  @HostListener('keyup', ['$event.target'])
  onKeyPress(input: HTMLInputElement & HTMLTextAreaElement) {
    if (!this.count) return this.stateChanges.next();
    this.calculate(input.value);
    this.stateChanges.next();
  }

  /**
   * It's better to use keypress and .charCode instead of keydown and .keyCode -
   * this way we can obtain real, printable character user typed, not relying on
   * keyCodes, which may vary between browsers or devices.
   * charCode contains code of the char as in Unicode.
   */
  @HostListener('keypress', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    if (this.type === 'number') {
      /**
       * Some old IE versions store charCode in .keyCode property.
       */
      const charCode = event.charCode || event.keyCode;
      const char = String.fromCharCode(charCode);

      if (char === '-' && this.min && parseFloat(this.min) >= 0) {
        event.preventDefault();
      }

      /**
       * This prevents user from typing minus/hyphen after already typed characters.
       */
      if (char === '-' && this.nativeValue.length > 0) {
        event.preventDefault();
      }

      if (char === 'e' && !this.allowExponential) {
        event.preventDefault();
      }
    }
  }

  private calculate(value: string) {
    this.counter.set(this.maxValue - value.length);
  }
}

import { HttpClientModule } from '@angular/common/http';
import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { ModuleConfig } from './model';
import { KEYCLOAK_OPTIONS } from './contant';
import { DeepLinkService, KeycloakTenantWebService } from './services';

@NgModule()
export class KeycloakTenantAuthenticationModule {
  private keycloakWebService = inject(KeycloakTenantWebService);

  constructor() {
    void this.keycloakWebService.init();
  }

  public static forRoot(
    config = new ModuleConfig()
  ): ModuleWithProviders<KeycloakTenantAuthenticationModule> {
    return {
      ngModule: KeycloakTenantAuthenticationModule,
      providers: [
        HttpClientModule,
        { provide: KEYCLOAK_OPTIONS, useValue: config.keycloakConfig },
        KeycloakTenantWebService,
        DeepLinkService,
        JwtHelperService
      ]
    };
  }
}

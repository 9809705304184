import { ModuleWithProviders, NgModule } from '@angular/core';
import { GOOGLE_MAPS_API_CONFIG } from '../services';
import { ComponentsModuleConfig } from './components.config';

@NgModule()
export class ComponentsModule {
  public static forRoot(
    config: ComponentsModuleConfig = {}
  ): ModuleWithProviders<ComponentsModule> {
    return {
      ngModule: ComponentsModule,
      providers: [
        {
          provide: GOOGLE_MAPS_API_CONFIG,
          useValue: config.googleApiConfig || {}
        }
      ]
    };
  }
}

<div class="w-100 d-flex flex-drow justify-content-between">
  <div
    class="attachment-item__info"
    (click)="!disableDownload() && attachment().url && onPreview()"
    [class.preview]="!disableDownload() && attachment().url"
  >
    @if (orderable()) {
      <div class="attachment-item__order-menu">
        <i class="icon icon--chevron-up" (click)="onMoveUp()"></i>
        <i class="icon icon--chevron-down" (click)="onMoveDown()"></i>
      </div>
    }
    @if (showPreview()) {
      <div class="attachment-item__preview">
        <app-attachment-preview
          [imgSrc]="imgSrc || ''"
          [isDocument]="isDocument()"
          [attachment]="attachment()"
          (preview)="onPreview()"
          (download)="onDownload()"
        >
        </app-attachment-preview>
      </div>
    }
    <div class="attachment-item__details">
      @if (editable()) {
        <app-simple-text-input
          [formControl]="nameControl"
          [hideIcon]="hideIcons"
        ></app-simple-text-input>
      }
      @if (!editable()) {
        <p class="default-s attachment-details__name">{{ name | translate }}</p>
      }
      <p class="default-s attachment-details__size">
        @if (attachment()?.size) {
          <span>{{ attachment()?.size | appFileSize }}</span>
        }
        @if (hasExifMetadata) {
          <span class="exif-metadata-info">{{
            'attachments.exif_metadata_info' | translate
          }}</span>
        }
      </p>
    </div>
  </div>

  <div class="attachment-item__actions upload-progress gap-3">
    @if (showDownload() && !hasFile) {
      <div
        class="download-attachment-button"
        [ngClass]="{ mr12: showRemove() }"
      >
        @if (!blockDownload() && !disableDownload()) {
          <a class="attachment-item__action-icon" (click)="onDownload()">
            <!-- {{attachment?.title || attachment?.name}} -->
            <svg-icon
              src="/assets/images/icons/icon-download.svg"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': 15 }"
              class="secondary-svg-color path rect"
            ></svg-icon>
          </a>
        }
        @if (blockDownload() || disableDownload()) {
          <span class="attachment-item__action-icon disabled">
            @if (!blockDownload() && disableDownload()) {
              <svg-icon
                src="/assets/images/icons/icon-download.svg"
                [applyClass]="true"
                [svgStyle]="{ 'width.px': 15 }"
                class="secondary-svg-color path rect"
              ></svg-icon>
            }
            @if (blockDownload()) {
              <svg-icon
                src="/assets/images/icons/lock-icon.svg"
                [applyClass]="true"
                [svgStyle]="{ 'width.px': 15 }"
                class="secondary-svg-color path rect"
              ></svg-icon>
            }
          </span>
        }
      </div>
    }
    @if (showRemove()) {
      <div class="attachment-item__action-icon">
        <svg-icon
          role="button"
          src="/assets/images/icons/icon-bin.svg"
          [applyClass]="true"
          [svgStyle]="{ 'width.px': 15 }"
          class="secondary-svg-color path rect"
          (click)="onRemove()"
        ></svg-icon>
      </div>
    }
    @if (editable()) {
      <div class="attachment-item__action-icon">
        <app-context-menu>
          <div menu-content>
            <app-context-menu-item (clickEvent)="editAttachment()"
              >{{ 'general.edit_attachment_a' | translate }}
            </app-context-menu-item>
          </div>
        </app-context-menu>
      </div>
    }
  </div>
</div>

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-wbs-tag',
  templateUrl: './wbs-tag.component.html',
  styleUrls: ['./wbs-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgbTooltip, TranslateModule]
})
export class WbsTagComponent {
  readonly tag = input<string>(undefined);
  readonly disableTooltip = input<boolean>(undefined);
  readonly enableUpperCase = input<boolean>(true);
}

export enum MarketingWorkflowState {
  NEW = 'NEW',
  MARKETABLE = 'MARKETABLE',
  GATHERING_APPLICATIONS = 'GATHERING_APPLICATIONS',
  VIEWING_APPOINTMENTS = 'VIEWING_APPOINTMENTS',
  WAITING_FOR_POSITIVE_FEEDBACK = 'WAITING_FOR_POSITIVE_FEEDBACK',
  SELECTION = 'SELECTION',
  TENANT_APPROVAL_PHASE = 'TENANT_APPROVAL_PHASE',
  RENTED_OUT = 'RENTED_OUT',
  SOLD = 'SOLD'
}

export enum MarketingWorkflowStateFilter {
  NEW = 'NEW',
  MARKETABLE = 'MARKETABLE',
  GATHERING_APPLICATIONS = 'GATHERING_APPLICATIONS',
  VIEWING_APPOINTMENTS = 'VIEWING_APPOINTMENTS',
  WAITING_FOR_POSITIVE_FEEDBACK = 'WAITING_FOR_POSITIVE_FEEDBACK',
  SELECTION = 'SELECTION',
  TENANT_APPROVAL_PHASE = 'TENANT_APPROVAL_PHASE',
  // Marketed combines RENTED_OUT and SOLD
  MARKETED = 'MARKETED'
}

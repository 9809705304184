import { TicketAttachmentDocumentType, TicketingVersion } from './ticket.model';

export type residentAppSettings = {
  accessRestrictionPeriodMonths: number;
  grantAccessAfterContractExpiration: boolean;
  ticketingVersion: TicketingVersion;
  allowContractlessTicketCreationForDamages: boolean;
  allowContractlessTicketCreationForOtherConcerns: boolean;
  askForMovingAddressOnEndingContract: boolean;
  digitalDocumentsReceivalMandatory: boolean;
  showStartPageBanner: boolean;
  startPageBannerHeadline: string;
  startPageBannerText: string;
  showFaqCard: boolean;
  faqCardHeadline: string;
  faqCardText: string;
  allowedConcernTicketAttachmentFileTypes: TicketAttachmentDocumentType[];
  allowedDamageTicketAttachmentFileTypes: TicketAttachmentDocumentType[];
};

export enum CatalogueType {
  GENERAL,
  FLAT,
  GARAGE,
  COMMERCIAL,
  NO_CONTRACT
}

enum TicketIssueType {
  DAMAGE,
  CONCERN
}

export enum MasterDataRequestType {
  TICKET = 'TICKET',
  EXTERNAL = 'EXTERNAL'
}

export enum MasterdataChangeType {
  NAME = 'NAME',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  ADDRESS = 'ADDRESS',
  PHONE = 'PHONE',
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
  PAYMENT = 'PAYMENT'
}

export type LandlordTicketCatalogue = {
  id: string;
  catalogueType: CatalogueType;
  issueType: TicketIssueType;
  lastUpdate: Date;
  downloadPossible: boolean;
};

export type TicketCategoryType = {
  id: string;
  publicId: string;
  name: string;
};

export type MasterDataChangeConfigurationType = {
  gracePeriod: number;
  masterdataChangeConfigurationItems: MasterDataConfigurationItemType[];
};

export type MasterDataConfigurationItemType = {
  changeType: MasterdataChangeType;
  requestType: MasterDataRequestType;
  ticketCategory?: TicketCategoryType;
};

export type MasterDataChangeConfigurationInput = {
  gracePeriod: number;
  masterdataChangeConfigurationItems: MasterDataConfigurationItemInput[];
};

export type MasterDataConfigurationItemInput = {
  changeType: MasterdataChangeType;
  requestType: MasterDataRequestType;
  ticketCategoryId?: string;
};

export interface UserPermissionRole {
  id: string;
  name: string;
  userPermissionRoleType: UserPermissionRoleType;
  userPermissions: UserPermission[];
}

export interface UserPermissionListFilter {
  page?: number;
  size?: number;
  sortBy?: UserRoleListSortByFields;
  sortOrder?: 'ASC' | 'DESC';
}

export interface UPCReturnType {
  permissionRoles: {
    nodes: UserPermissionRole[];
  };
}

export enum UserPermissionRoleType {
  SYSTEM = 'SYSTEM',
  CUSTOMER = 'CUSTOMER'
}

export enum UserPermission {
  DASHBOARD = 'DASHBOARD',
  OBJECTS = 'OBJECTS',
  PROJECTS = 'PROJECTS',
  APPOINTMENTS = 'APPOINTMENTS',
  CONTRACTS = 'CONTRACTS',
  SCHUFA = 'SCHUFA',
  CUSTOMER_PORTAL = 'CUSTOMER_PORTAL',
  VMP_CONTACTS = 'VMP_CONTACTS',
  RESIDENT_CONTACTS = 'RESIDENT_CONTACTS',
  MESSENGER = 'MESSENGER',
  REPORTING = 'REPORTING',
  ADMINISTRATION = 'ADMINISTRATION',
  UVI_CONTROL = 'UVI_CONTROL',
  MASTERDATA_CHANGE_CONFIGURATION = 'MASTERDATA_CHANGE_CONFIGURATION',
  TENANT_APPROVAL_PROCESS_APPROVALS = 'TENANT_APPROVAL_PROCESS_APPROVALS'
}

export enum UserRoleListSortByFields {
  NAME = 'NAME',
  USER_PERMISSION_ROLE_TYPE = 'USER_PERMISSION_ROLE_TYPE',
  UPDATED = 'UPDATED',
  CREATED = 'CREATED'
}

/**
 * userPermissionRoutes is used to link permissions to their respective route.
 * It is used when the user does not have access to dashboard so we redirect
 * to the first permission route existing in the permissions list
 *
 * TODO: add further permissions and routes if needed.
 *
 * @example:
 * user permissions -> ['TENANT_APPROVAL_PROCESS_APPROVALS'] -> redirect to 'tenant-approval'
 *
 *
 * user permissions -> ['DASHBOARD', 'TENANT_APPROVAL_PROCESS_APPROVALS']  -> proceed to dashboard
 */
export const userPermissionRoutes: Partial<Record<UserPermission, string>> = {
  [UserPermission.TENANT_APPROVAL_PROCESS_APPROVALS]: 'tenant-approval',
  [UserPermission.APPOINTMENTS]: 'viewings'
};

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  output
} from '@angular/core';
import { NgClass } from '@angular/common';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

import { Toast } from './model/toast.model';
import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOut', [
      state('fade', style({ opacity: 1 })),
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate('0.3s ease-in')
      ]),
      transition(':leave', [
        animate(
          '0.3s ease-out',
          style({
            opacity: 0
          })
        )
      ])
    ])
  ],
  imports: [NgClass]
})
export class ToastContainerComponent {
  #toastService = inject(ToastService);
  readonly toasts = this.#toastService.toasts;

  readonly removeToast = output();
  readonly toastClicked = output<Toast>();

  public toastClick(toast: Toast) {
    this.toastClicked.emit(toast);
  }
}

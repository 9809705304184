!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define([], t) : "object" == typeof exports ? exports.Survicate = t() : e.Survicate = t();
}(self, () => (() => {
  "use strict";

  var e = {
      734: function (e, t, n) {
        var i = this && this.__awaiter || function (e, t, n, i) {
          return new (n || (n = Promise))(function (r, o) {
            function a(e) {
              try {
                c(i.next(e));
              } catch (e) {
                o(e);
              }
            }
            function s(e) {
              try {
                c(i.throw(e));
              } catch (e) {
                o(e);
              }
            }
            function c(e) {
              var t;
              e.done ? r(e.value) : (t = e.value, t instanceof n ? t : new n(function (e) {
                e(t);
              })).then(a, s);
            }
            c((i = i.apply(e, t || [])).next());
          });
        };
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.getSurvicateInstance = t.initSurvicate = t.AppearMethod = t.ApiEvent = void 0;
        const r = n(807);
        Object.defineProperty(t, "ApiEvent", {
          enumerable: !0,
          get: function () {
            return r.ApiEvent;
          }
        }), Object.defineProperty(t, "AppearMethod", {
          enumerable: !0,
          get: function () {
            return r.AppearMethod;
          }
        });
        const o = n(870);
        let a = null;
        t.initSurvicate = e => i(void 0, void 0, void 0, function* () {
          if (!a) {
            const t = new o.Survicate(e);
            yield t.ready(), a = t;
          }
        });
        t.getSurvicateInstance = () => (a || console.warn("Survicate was not initialized"), a);
      },
      807: (e, t) => {
        var n, i;
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.ApiEvent = t.AppearMethod = void 0, function (e) {
          e.immediately = "immediately", e.delayed = "delayed", e.exitIntent = "exitIntent", e.scroll = "onScroll";
        }(n || (t.AppearMethod = n = {})), function (e) {
          e.questionAnswered = "question_answered", e.surveyDisplayed = "survey_displayed", e.surveyCompleted = "survey_completed", e.surveyClosed = "survey_closed";
        }(i || (t.ApiEvent = i = {}));
      },
      870: function (e, t) {
        var n = this && this.__awaiter || function (e, t, n, i) {
          return new (n || (n = Promise))(function (r, o) {
            function a(e) {
              try {
                c(i.next(e));
              } catch (e) {
                o(e);
              }
            }
            function s(e) {
              try {
                c(i.throw(e));
              } catch (e) {
                o(e);
              }
            }
            function c(e) {
              var t;
              e.done ? r(e.value) : (t = e.value, t instanceof n ? t : new n(function (e) {
                e(t);
              })).then(a, s);
            }
            c((i = i.apply(e, t || [])).next());
          });
        };
        Object.defineProperty(t, "__esModule", {
          value: !0
        }), t.Survicate = void 0;
        t.Survicate = class {
          constructor(e) {
            this.initialized = this.initScript(e).catch(e => {
              console.error("Error loading Survicate script:", e);
            });
          }
          initScript(e) {
            return new Promise((t, n) => {
              window.addEventListener("SurvicateReady", () => {
                t();
              });
              try {
                this.loadScript(e, n);
              } catch (e) {
                n(e);
              }
            });
          }
          loadScript({
            workspaceKey: e,
            traits: t,
            disableTargeting: n,
            nonce: i,
            disableSensitiveDataPersistence: r
          }, o) {
            if (!document.head) return void o(new Error("Unable to append the Survicate script because the document head is not available"));
            window._sva = window._sva || {}, t && (window._sva.traits = t), n && (window._sva.disableTargeting = !0), r && (window._sva.disableSensitiveDataPersistence = !0);
            const a = document.createElement("script");
            i && (window._sva.nonce = i, a.setAttribute("nonce", i)), a.type = "text/javascript", a.async = !0, a.src = `https://survey.survicate.com/workspaces/${e}/web_surveys.js`, document.head.appendChild(a);
          }
          ready() {
            return n(this, void 0, void 0, function* () {
              return this.initialized;
            });
          }
          showSurvey(e, t) {
            return window._sva.showSurvey(e, t);
          }
          retarget() {
            window._sva.retarget();
          }
          getVisitorId() {
            return window._sva.getVisitorId();
          }
          setVisitorTraits(e) {
            window._sva.setVisitorTraits(e);
          }
          destroyVisitor(e) {
            window._sva.destroyVisitor(e);
          }
          addEventListener(e, t) {
            return window._sva.addEventListener(e, t);
          }
          removeEventListener(e) {
            window._sva.removeEventListener(e);
          }
          invokeEvent(e, t) {
            window._sva.invokeEvent(e, t);
          }
        };
      }
    },
    t = {};
  var n = function n(i) {
    var r = t[i];
    if (void 0 !== r) return r.exports;
    var o = t[i] = {
      exports: {}
    };
    return e[i].call(o.exports, o, o.exports, n), o.exports;
  }(734);
  return n;
})());
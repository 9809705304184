import { Component, inject, input, output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { filter } from 'rxjs/operators';

import {
  AddressFormConfig,
  CustomerSettings,
  FlatSelectType,
  LandlordUser,
  NameValue
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { FullNamePipe } from '../../../../pipes/full-name.pipe';
import { ImageSizePipe } from '../../../../pipes/image-size.pipe';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { AddressFormComponent } from '../../address-form/address-form.component';
import { AppInputDirective } from '../../form/controls/input/input.directive';
import { SelectComponent } from '../../form/controls/select/select.component';
import { FormFieldLabelComponent } from '../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../form/form-field/form-field.component';
import { AvatarComponent } from '../../avatar/avatar.component';
import { DropdownSelectComponent } from '../../form/controls/dropdown-select/dropdown-select.component';
import { DigitalContractFormService } from '../../../../digital-contract';
import { PhoneInputComponent } from '../../../molecules';
import { HintComponent } from '../../hint/hint.component';

@UntilDestroy()
@Component({
  selector: 'app-digital-contract-signer',
  templateUrl: './digital-contract-signer.component.html',
  styleUrls: ['./digital-contract-signer.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SvgIconComponent,
    DropdownSelectComponent,
    AvatarComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    SelectComponent,
    AppInputDirective,
    AddressFormComponent,
    ButtonComponent,
    TranslateModule,
    ImageSizePipe,
    FullNamePipe,
    PhoneInputComponent,
    HintComponent
  ]
})
export class DigitalContractSignerComponent {
  private formService = inject(DigitalContractFormService);

  readonly readOnly = input(false);
  readonly headerTitle = input<string>(undefined);
  readonly showRegion = input(true);
  readonly genderTypes = input<FlatSelectType[]>(undefined);
  readonly isGenderRequired = input(false);
  readonly isAddressRequired = input(false);
  readonly isPhoneRequired = input(false);
  readonly templates = input<LandlordUser[]>(undefined);
  readonly form = input<FormArray>(undefined);
  readonly internationalPhoneOptions = input<NameValue[]>(undefined);
  readonly customerSettings = input<CustomerSettings>(undefined);
  readonly parentFormTouched = input<boolean>(undefined);
  readonly addressFormConfig = input<AddressFormConfig, AddressFormConfig>(
    {
      showCountry: true
    },
    {
      transform: v => ({ ...v, readOnlyCountryName: this.readOnly() })
    }
  );
  readonly validityChange = output<boolean>();

  public value: any;

  public get getDigitalContractSignerFormArray() {
    return this.form();
  }

  public get getDigitalContractSignerControls() {
    return this.getDigitalContractSignerFormArray?.controls as FormGroup[];
  }

  public getControlAt(i: number) {
    return this.getDigitalContractSignerFormArray.at(i).get('address');
  }

  public addDigitalContractSigner() {
    this.getDigitalContractSignerFormArray.push(this.getSignerFormGroup());
  }

  public removeDigitalContractSigner(index: number) {
    this.getDigitalContractSignerFormArray.removeAt(index);
  }

  public getGender(i: number) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.getDigitalContractSignerFormArray.at(i).get('gender').value;
  }

  public onKeyUp(form: FormGroup) {
    const tmpl = form.get('selectedTemplate');
    if (tmpl?.value !== null) {
      form.get('selectedTemplate').patchValue(null);
    }
  }

  private getSignerFormGroup() {
    const control = this.formService.getSignerFormGroup({
      isAddressRequired: this.isAddressRequired(),
      isGenderRequired: this.isGenderRequired(),
      isReadOnly: this.readOnly(),
      isPhoneRequired: this.isPhoneRequired(),
      genderTypes: this.genderTypes()
    });
    control
      .get('selectedTemplate')
      .valueChanges.pipe(
        filter(templateId => !!templateId),
        untilDestroyed(this)
      )
      .subscribe(item => {
        this.formService.onTemplateChange(
          item,
          this.templates(),
          control,
          this.isPhoneRequired()
        );
      });
    return control;
  }
}

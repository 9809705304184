import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '../../../components/legacy/modal';
import { ModalContentComponent } from '../../../components/legacy/modal/modal-content/modal-content.component';
import { ModalFooterComponent } from '../../../components/legacy/modal/modal-footer/modal-footer.component';
import { ButtonComponent } from '../../../components/atoms/button';

@Component({
  selector: 'app-downgrade-modal',
  templateUrl: './downgrade-modal.component.html',
  styleUrls: ['./downgrade-modal.component.scss'],
  imports: [
    TranslateModule,
    ModalComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ButtonComponent
  ]
})
export class DowngradeModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  close() {
    this.ngbActiveModal.close();
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }
}

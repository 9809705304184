<div class="toasts-container" role="alert">
  @for (toast of toasts(); track toast.id) {
    <div
      @inOut
      class="toast"
      [id]="'toast-' + toast.id"
      [ngClass]="toast.type"
      (click)="toastClick(toast)"
    >
      <div class="toast__content">
        <div class="toast__message-container">
          <span class="toast__message" [innerHTML]="toast.message"></span>
        </div>
      </div>
    </div>
  }
</div>

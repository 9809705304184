import { inject, Injectable } from '@angular/core';
import { initSurvicate } from '@survicate/survicate-web-surveys-wrapper/widget_wrapper';
import { INFRASTRUCTURE_CONFIG } from '../infrastructure/infrastructure-config.token';
import type { ConfigModel } from '@survicate/survicate-web-surveys-wrapper';

@Injectable({
  providedIn: 'root'
})
export class SurvicateService {
  #config = inject(INFRASTRUCTURE_CONFIG);

  public init(email: string) {
    if (
      this.#config.environment.deploymentEnv === 'sandbox' ||
      this.#config.environment.deploymentEnv === 'production'
    ) {
      const workspaceKey = this.#config.environment.survicate_workspace_key;
      const config: ConfigModel = { workspaceKey, traits: { email } };
      return initSurvicate(config);
    }
  }
}

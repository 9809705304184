import { Component, inject, input, OnInit, output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Gallery,
  GalleryItem,
  ImageItem,
  ImageSize,
  ThumbnailsPosition
} from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';

import {
  ApplicantStatus,
  Attachment,
  AvailableFrom,
  IconTypeEnum
} from '@ui/shared/models';

import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyPipe, DecimalPipe, NgClass } from '@angular/common';
import { DateTimePipe } from '../../../../pipes/date-time.pipe';
import { AddressPipe } from '../../../../pipes/address.pipe';
import { LandlordInfoComponent } from '../../landlord-info/landlord-info.component';
import { BadgeComponent } from '../../../atoms/badge/badge.component';
import { ApplicationStatusPipelineComponent } from '../../status-pipelines/application-status-pipeline/application-status-pipeline.component';
import { ImageComponent } from '../../../atoms/image/image.component';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { ContextMenuItemComponent } from '../../context-menu/context-menu-item/context-menu-item.component';
import { ContextMenuComponent } from '../../context-menu/context-menu.component';
import { CardComponent } from '../../../atoms/card/card.component';
import { ImageSizePipe } from '../../../../pipes';
import { LandlordInfoData } from '../../landlord-info/landlord-info.model';
import { Elevation, ElevationType } from '../../../../directives';
import {
  buildBEMClassNamesByGivenBaseClassAndFlags,
  isRentalProperty,
  isTouchScreen,
  isValueNotNullAndUndefined
} from '../../../../utils';

import { imageExtension } from '../../../../config';
import { CardBorderStyleEnum } from '../../../atoms/card';
import { PropertyCardPropertyData } from './property-card.model';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss'],
  imports: [
    CardComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    ButtonComponent,
    ImageComponent,
    ApplicationStatusPipelineComponent,
    BadgeComponent,
    NgClass,
    LandlordInfoComponent,
    NgbTooltip,
    DecimalPipe,
    CurrencyPipe,
    TranslateModule,
    AddressPipe,
    DateTimePipe
  ],
  providers: [ImageSizePipe]
})
export class PropertyCardComponent implements OnInit {
  private lightbox = inject(Lightbox);
  private gallery = inject(Gallery);
  private imageSizePipe = inject(ImageSizePipe);
  private sanitizer = inject(DomSanitizer);
  private translate = inject(TranslateService);

  propertyData = input<PropertyCardPropertyData>(undefined);
  readonly landlordInfoData = input<LandlordInfoData>(undefined);

  readonly pageView = input(false);
  readonly isRented = input(false);
  readonly userIsTenant = input(false);
  readonly actionNeeded = input(false);
  readonly isApplyPage = input(false);
  readonly isProposal = input(false);
  readonly isProcessing = input(false);
  readonly isShowSelfDisclosure = input(false);
  readonly isNotBlocked = input(false);
  readonly fadeOutCard = input(false);
  elevation = input<ElevationType>(Elevation.ZERO);
  withoutBorder = input(false);

  readonly hasCustomQuestions = input(false);
  readonly hasUnansweredQuestions = input(false);
  readonly hasDeclaredIntention = input(false);
  readonly hasDeniedIntention = input(false);

  showAddress = input(false);
  showLandlordInfo = input(true);
  readonly showAvailableFromDate = input(true);
  readonly showExternalId = input(true);

  enableContextMenu = input(true);
  readonly enableAppointmentSelection = input(false);
  readonly enableImageGallery = input(false);
  readonly enableExportAppointmentToCalendar = input(false);
  readonly enableDownloadPDF = input(false);

  readonly applicationStatus = input<ApplicantStatus | null>(undefined);
  readonly actionBadgeContent = input('');

  readonly notificationBellClickEvent = output();
  readonly answerCustomQuestionsClickEvent = output();
  readonly declareIntentClickEvent = output();
  readonly denyIntentClickEvent = output();
  readonly exportAppointmentToCalendarClickEvent = output();
  readonly downloadPDFClickEvent = output();
  readonly removePropertyClickEvent = output();
  readonly selectAppointmentClickEvent = output();
  readonly showSelfDisclosureClickEvent = output();
  readonly showDetailsClickEvent = output();

  public baseClass = 'property-card';
  public imageGalleryItems: GalleryItem[];
  public IconTypeEnum = IconTypeEnum;

  public images: Attachment[] = [];
  public hasImages = false;

  public getClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      ['page-view']: this.pageView()
    });
  }

  public getElevation(): ElevationType {
    return this.pageView() ? Elevation.ZERO : this.elevation();
  }

  public getContextMenuClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__context-menu`,
      {
        hide: this.hideButtonsInitially
      }
    );
  }

  public getDownloadPDFButtonClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__download-pdf-button`,
      {
        hide: this.hideButtonsInitially
      }
    );
  }

  public getBodyClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__body`,
      {
        ['out-faded']: !this.pageView() && this.fadeOutCard()
      }
    );
  }

  public getImageClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__image`,
      {
        clickable: this.hasImages
      }
    );
  }

  public get cardBorderStyle(): CardBorderStyleEnum {
    return this.pageView() || this.withoutBorder()
      ? CardBorderStyleEnum.NONE
      : this.highlightCardAsActionNeeded
        ? CardBorderStyleEnum.ACTIVE
        : this.highlightCardAsUserIsTenant
          ? CardBorderStyleEnum.WARNING
          : CardBorderStyleEnum.DEFAULT;
  }

  public get cardBorderRadius(): boolean {
    return !this.pageView();
  }

  public get hideButtonsInitially(): boolean {
    return !this.pageView() && !isTouchScreen();
  }

  public get highlightCardAsActionNeeded() {
    return !this.pageView() && this.actionNeeded();
  }

  public get highlightCardAsUserIsTenant() {
    return !this.pageView() && this.userIsTenant();
  }

  public get showImageGallery(): boolean {
    return this.pageView() && this.hasImages && this.enableImageGallery();
  }

  public get showApplicationStatusPipeline(): boolean {
    return this.isNotNullAndUndefined(this.applicationStatus());
  }

  public get showContextMenu(): boolean {
    return (
      this.enableContextMenu() && !this.isApplyPage() && !this.isProposal()
    );
  }

  public get getBackgroundImage() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `url(${this.propertyData().attachments?.[0]?.url})`
    );
  }

  public get galleryButtonLabel() {
    const pictureCount = this.propertyData().attachments?.length;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.translate.instant(
      `property.gallery_button_${pictureCount === 1 ? 'singular' : 'plural'}_l`
    );
  }

  public get isRentalObject(): boolean {
    return isRentalProperty(this.propertyData()?.marketingType);
  }

  public checkDateAndStringValidity(availableFrom: AvailableFrom): boolean {
    if (
      isValueNotNullAndUndefined(
        availableFrom?.dateAvailable || availableFrom?.stringAvailable
      ) &&
      (availableFrom?.stringAvailable !== '' || availableFrom?.dateAvailable)
    ) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.images = [
      ...this.propertyData().attachments,
      ...(this.propertyData().documents || []).filter(a => {
        if (!a.extension) return false;
        return imageExtension.includes(a.extension.toLowerCase());
      })
    ];

    this.hasImages = this.images.length > 0;

    if (this.enableImageGallery()) {
      this.loadLightbox(this.images);
    }
  }

  public loadLightbox(attachments: Attachment[]) {
    this.imageGalleryItems = attachments?.map(attachment => {
      return new ImageItem({
        src: attachment?.url,
        thumb: this.imageSizePipe.transform(attachment, 'S')
      });
    });

    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Bottom
    });
    // Load items into the lightbox gallery ref
    lightboxRef.load(this.imageGalleryItems);
  }

  public isNotNullAndUndefined(value: any) {
    return isValueNotNullAndUndefined(value);
  }

  public notificationBellClick(): void {
    this.notificationBellClickEvent.emit();
  }

  public answerCustomQuestionsClick(): void {
    this.answerCustomQuestionsClickEvent.emit();
  }

  public declareIntentClick(): void {
    this.declareIntentClickEvent.emit();
  }

  public denyIntentClick(): void {
    this.denyIntentClickEvent.emit();
  }

  public exportAppointmentToCalendarClick(): void {
    this.exportAppointmentToCalendarClickEvent.emit();
  }

  public downloadPDFClick(): void {
    this.downloadPDFClickEvent.emit();
  }

  public removePropertyClick(): void {
    this.removePropertyClickEvent.emit();
  }

  public showSelfDisclosureClick(): void {
    this.showSelfDisclosureClickEvent.emit();
  }

  public selectAppointmentClick(): void {
    this.selectAppointmentClickEvent.emit();
  }

  public showDetailsClick(): void {
    this.showDetailsClickEvent.emit();
  }

  public showImageGalleryClick() {
    if (!this.showImageGallery) return;
    this.lightbox.open(0, 'lightbox', {
      panelClass: 'fullscreen'
    });
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';

import { ContactTag, PropertiesTag, SystemTag } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, SlicePipe } from '@angular/common';
import { TagComponent } from '../tag/tag.component';
import { ContextMenuItemComponent } from '../context-menu/context-menu-item/context-menu-item.component';
import { BadgeComponent } from '../../atoms/badge/badge.component';
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { ContextMenuTooltipPlacementEnum } from '../context-menu';
import { LabelListTagComponent } from './label-list-tag/label-list-tag.component';

@Component({
  selector: 'app-label-list',
  templateUrl: './label-list.component.html',
  styleUrls: ['./label-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    LabelListTagComponent,
    ContextMenuComponent,
    BadgeComponent,
    ContextMenuItemComponent,
    NgbTooltip,
    TagComponent,
    SlicePipe,
    TranslateModule
  ]
})
export class LabelListComponent {
  readonly assignedTags =
    input<Array<ContactTag | PropertiesTag | SystemTag>>(undefined);
  readonly assignableTags =
    input<Array<ContactTag | PropertiesTag | SystemTag>>(undefined);
  readonly isSystemTag = input(false);
  readonly amountBeforeTruncation = input(2);
  readonly truncateLabels = input(true);
  readonly readonly = input(false);
  readonly multilineMode = input(false);
  readonly isAddLabelObject = input(false);
  readonly assignTag = output<ContactTag | PropertiesTag | SystemTag>();
  readonly unassignTag = output<ContactTag | PropertiesTag | SystemTag>();

  public onAssignTag(tag: ContactTag | PropertiesTag | SystemTag): void {
    this.assignTag.emit(tag);
  }

  public onUnassignTag(tag: ContactTag | PropertiesTag | SystemTag): void {
    this.unassignTag.emit(tag);
  }

  protected readonly ContextMenuTooltipPlacementEnum =
    ContextMenuTooltipPlacementEnum;
}

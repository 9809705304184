import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';

import { IconTypeEnum } from '@ui/shared/models';
import { NgClass } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {
  Elevation,
  ElevationDirective,
  ElevationType
} from '../../../directives';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from '../../../utils';
import {
  BadgeBorderRadiusEnum,
  BadgeColorEnum,
  BadgeSizeEnum
} from './badge.enum';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgbTooltip, NgClass, ElevationDirective]
})
export class BadgeComponent {
  readonly tooltipText = input('');
  readonly borderColor = input('');
  readonly borderType = input('');
  readonly color = input(BadgeColorEnum.NEUTRAL);
  readonly size = input(BadgeSizeEnum.NORMAL);
  readonly borderWidth = input('');
  readonly borderRadius = input(BadgeBorderRadiusEnum.SMALL);

  readonly clickable = input(false);
  readonly elevation = input<ElevationType>(Elevation.ZERO);
  readonly withCloseButton = input(false);
  readonly uppercase = input(true);
  readonly fontBold = input(false);

  readonly icon = input<IconTypeEnum>(null);

  readonly clickEvent = output<MouseEvent>();
  readonly closeEvent = output<MouseEvent>();

  public baseClass = 'badge';

  public getClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      [`color-${this.color()}`]: !!this.color(),
      [`size-${this.size()}`]: !!this.size(),
      [`border-radius-${this.borderRadius()}`]: !!this.borderRadius(),
      [`border-color-${this.borderColor()}`]: !!this.borderColor(),
      [`border-style-${this.borderType()}`]: !!this.borderRadius(),
      [`border-width-${this.borderWidth()}`]: !!this.borderWidth(),
      clickable: this.clickable()
    });
  }

  public badgeClick(event: MouseEvent): void {
    if (this.clickable()) {
      this.clickEvent.emit(event);
    }
  }

  public closeButtonClick(event: MouseEvent): void {
    if (this.withCloseButton()) {
      this.closeEvent.emit(event);
    }
  }
}

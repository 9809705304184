import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SlicePipe } from '@angular/common';
import {
  ContextMenuComponent,
  ContextMenuItemComponent,
  ContextMenuTooltipPlacementEnum
} from '../context-menu';
import { BadgeComponent } from '../../atoms/badge';
import { WbsTagComponent } from '../wbs-tag/wbs-tag.component';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    BadgeComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    SlicePipe,
    WbsTagComponent
  ]
})
export class TagListComponent {
  readonly tags = input<string[]>(undefined);
  readonly amountBeforeTruncation = input(2);
  readonly truncateLabels = input(true);
  readonly readonly = input(false);
  readonly enableUpperCase = input(true);

  protected readonly ContextMenuTooltipPlacementEnum =
    ContextMenuTooltipPlacementEnum;
}

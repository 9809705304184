import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  imports: [NgbTooltip, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HintComponent {
  readonly placement = input('top');
  readonly container = input<string>(undefined);
}

import { ModuleWithProviders, NgModule } from '@angular/core';

import { ThemeService } from './theme.service';
import { ACTIVE_THEME, ThemeOptions, THEMES } from './symbols';

// tslint:disable-next-line:max-line-length
/** Source: https://stackblitz.com/edit/angular-themeing?embed=1&file=src%2Fapp%2Fapp.component.ts&source=post_page-----3c78a5b20b24----------------------
 * Blog from Austin from Jun 14, 2018
 * https://medium.com/@amcdnl/theming-angular-with-css-variables-3c78a5b20b24
 */

@NgModule({
  providers: [ThemeService]
})
export class ThemeModule {
  static forRoot(options: ThemeOptions): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        {
          provide: THEMES,
          useValue: options.themes
        },
        {
          provide: ACTIVE_THEME,
          useValue: options.active
        }
      ]
    };
  }
}

import { inject, Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  Attachment,
  SelfDisclosureModel,
  SelfDisclosureQuestion,
  SelfDisclosureQuestionType
} from '@ui/shared/models';

import { ibanValidator } from 'ngx-iban';
import {
  customEmailValidator,
  getArrayListValidator,
  getRequiredValidator,
  isValidDateValidator,
  taxIdValidator
} from '../components/legacy/form/controls/validation';

@Injectable({ providedIn: 'root' })
export class SelfDisclosureService {
  private fb = inject(FormBuilder);

  public createQuestionFormArray(
    selfDisclosureModel: SelfDisclosureModel,
    answeredQuestions: SelfDisclosureQuestion[]
  ) {
    const formArray: FormArray = this.fb.array([]);

    if (selfDisclosureModel) {
      selfDisclosureModel.questions.forEach((element, index) => {
        const question = this.getQuestion(element);
        const answeredQuestion = this.getById(answeredQuestions, question.id);

        if (
          question.type === SelfDisclosureQuestionType.BOOLEAN ||
          question.type === SelfDisclosureQuestionType.SELECT
        ) {
          const groupConfig = this.getBooleanSelectConfig(
            this.isRequired(question)
          );
          const group = this.fb.group(groupConfig);
          formArray.push(group);
        } else if (question.type === SelfDisclosureQuestionType.CHILDREN) {
          const childListGroup = this.fb.group(
            this.getChildrenConfig(this.isRequired(question))
          );
          formArray.push(childListGroup);
          if (answeredQuestion && answeredQuestion.answer) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            answeredQuestion.answer.forEach(() => {
              const childFormGroup = this.fb.group(
                this.getChildConfig(this.isRequired(question))
              );
              (childListGroup.get('answer') as FormArray).push(childFormGroup);
            });
          }
        } else if (question.type === SelfDisclosureQuestionType.PERSONS) {
          const personListGroup = this.fb.group(
            this.getPersonListConfig(this.isRequired(question))
          );
          formArray.push(personListGroup);
          if (answeredQuestion) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            answeredQuestion.answer.forEach(person => {
              const personFormGroup = this.fb.group(
                this.getPersonConfig(this.isRequired(question))
              );

              selfDisclosureModel.confirmations.forEach(text => {
                (personFormGroup.get('confirmations') as FormArray).push(
                  this.fb.group({
                    checked: [null, Validators.requiredTrue],
                    text: [text]
                  })
                );
              });

              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              person['subQuestions'].forEach(subQuestion => {
                const mandatory =
                  subQuestion.mandatory &&
                  subQuestion.showSelfDisclosureQuestions;
                const subQuestionFormGroup = this.fb.group(
                  this.getConfigForSubQuestion(subQuestion.type, mandatory)
                );
                (personFormGroup.get('subQuestions') as FormArray).push(
                  subQuestionFormGroup
                );
              });
              (personListGroup.get('answer') as FormArray).push(
                personFormGroup
              );
            });
          }
        } else if (question.type === SelfDisclosureQuestionType.PERSON) {
          const personGroup = this.fb.group(
            this.getPersonConfig(this.isRequired(question))
          );
          formArray.push(personGroup);
          selfDisclosureModel.confirmations.forEach(text => {
            (personGroup.get('confirmations') as FormArray).push(
              this.fb.group({
                checked: [null, Validators.requiredTrue],
                text: [text]
              })
            );
          });

          if (question.subQuestions && question.subQuestions.length > 0) {
            question.subQuestions.forEach(subQuestion => {
              const mandatory =
                subQuestion.mandatory &&
                subQuestion.showSelfDisclosureQuestions;
              const subQuestionFromGroup = this.fb.group(
                this.getConfigForSubQuestion(subQuestion.type, mandatory)
              );
              (formArray.at(index).get('subQuestions') as FormArray).push(
                subQuestionFromGroup
              );
            });
          }
        } else if (question.type === SelfDisclosureQuestionType.FLAT) {
          formArray.push(
            this.fb.group(this.getFlatConfig(this.isRequired(question)))
          );
        } else if (question.type === SelfDisclosureQuestionType.ADDRESS) {
          formArray.push(
            this.fb.group(this.getAddressConfig(this.isRequired(question)))
          );
        } else if (question.type === SelfDisclosureQuestionType.EMPLOYMENT) {
          formArray.push(
            this.fb.group(this.getEmploymentConfig(this.isRequired(question)))
          );
        } else if (question.type === SelfDisclosureQuestionType.DOCUMENT) {
          formArray.push(
            this.fb.group(this.getDocumentConfig(this.isRequired(question)))
          );
        } else {
          formArray.push(
            this.fb.group(
              this.getBooleanSelectConfig(this.isRequired(question))
            )
          );
        }
        if (answeredQuestion) {
          formArray.at(index).patchValue(answeredQuestion);
        } else {
          formArray.at(index).patchValue(question);
        }
      });
    }
    return formArray;
  }

  public getFlatControlGroup(required: boolean): FormGroup {
    return this.fb.group({
      city: [{ value: '', disabled: true }, getRequiredValidator(required)],
      street: [{ value: '', disabled: true }, getRequiredValidator(required)],
      numberOfRooms: [
        { value: '', disabled: true },
        getRequiredValidator(required)
      ],
      sizeInSm: [{ value: '', disabled: true }],
      totalRent: [{ value: '', disabled: true }]
    });
  }

  private getAddressControlGroup(required: boolean): FormGroup {
    return this.fb.group({
      country: ['', getRequiredValidator(required)],
      city: ['', getRequiredValidator(required)],
      street: ['', getRequiredValidator(required)],
      houseNumber: ['', getRequiredValidator(required)],
      zipCode: ['', getRequiredValidator(required)],
      phone: [
        '',
        Validators.compose([
          getRequiredValidator(required),
          Validators.maxLength(35)
        ])
      ],
      email: [
        '',
        Validators.compose([
          getRequiredValidator(required),
          customEmailValidator
        ])
      ]
    });
  }

  public getBooleanSelectConfig(required: boolean) {
    return {
      answer: [null, getRequiredValidator(required)],
      comment: [null],
      commentHint: [null],
      commentAllowed: [null],
      uploadAllowed: [false],
      uploadHint: [''],
      upload: [null],
      options: [null],
      showSelfDisclosureQuestions: [null],
      id: [null],
      type: [null],
      title: [null]
    };
  }

  public getIbanQuestionConfig(required: boolean) {
    return {
      answer: ['', [getRequiredValidator(required), ibanValidator()]],
      showSelfDisclosureQuestions: [null],
      id: [null],
      type: [null],
      title: [null]
    };
  }

  public getTaxIdQuestionConfig(required: boolean) {
    return {
      answer: ['', [getRequiredValidator(required), taxIdValidator]],
      showSelfDisclosureQuestions: [null],
      id: [null],
      type: [null],
      title: [null]
    };
  }

  public getFlatConfig(required: boolean) {
    return {
      answer: this.getFlatControlGroup(required),
      comment: [null],
      commentHint: [null],
      commentAllowed: [null],
      showSelfDisclosureQuestions: [null],
      id: [null],
      type: [null],
      title: [null]
    };
  }

  public getDocumentConfig(required: boolean) {
    return {
      answer: [null],
      upload: [null, getRequiredValidator(required)],
      comment: [null],
      commentHint: [null],
      commentAllowed: [null],
      showSelfDisclosureQuestions: [null],
      id: [null],
      type: [null],
      title: [null]
    };
  }

  public getAddressConfig(required: boolean) {
    return {
      answer: this.getAddressControlGroup(required),
      comment: [null],
      commentHint: [null],
      commentAllowed: [null],
      showSelfDisclosureQuestions: [null],
      id: [null],
      type: [null],
      title: [null]
    };
  }

  public getEmploymentConfig(required: boolean) {
    return {
      answer: this.fb.group({
        job: [null, getRequiredValidator(required)],
        employer: [null, getRequiredValidator(required)],
        netIncome: [null, getRequiredValidator(required)]
      }),
      comment: [null],
      commentHint: [null],
      commentAllowed: [null],
      showSelfDisclosureQuestions: [null],
      id: [null],
      type: [null],
      title: [null]
    };
  }

  public getPersonConfig(required: boolean) {
    return {
      answer: this.fb.group({
        firstName: [null, getRequiredValidator(required)],
        lastName: [null, getRequiredValidator(required)],
        birthName: [null],
        birthPlace: [null, getRequiredValidator(required)],
        birthDate: [
          null,
          Validators.compose([
            isValidDateValidator,
            getRequiredValidator(required)
          ])
        ]
      }),
      subQuestions: this.fb.array([]),
      confirmations: this.fb.array([]),
      showSelfDisclosureQuestions: [null],
      id: [null],
      title: [null],
      type: [null]
    };
  }

  public getChildConfig(required: boolean) {
    return {
      answer: this.fb.group({
        firstName: [null, getRequiredValidator(required)],
        lastName: [null, getRequiredValidator(required)],
        birthDate: [
          null,
          Validators.compose([
            isValidDateValidator,
            getRequiredValidator(required)
          ])
        ]
      }),
      showSelfDisclosureQuestions: [null],
      id: [null],
      title: [null],
      type: [null]
    };
  }

  public getChildrenConfig(required: boolean) {
    return {
      answer: this.fb.array([], getArrayListValidator(required)),
      showSelfDisclosureQuestions: [null],
      answerUnavailable: [false],
      id: [null],
      title: [null],
      type: [null]
    };
  }

  public getPersonListConfig(required: boolean) {
    return {
      answer: this.fb.array([], getArrayListValidator(required)),
      showSelfDisclosureQuestions: [null],
      answerUnavailable: [false],
      id: [null],
      title: [null],
      type: [null]
    };
  }

  public getSubQuestionConfig(required: boolean) {
    return {
      question: this.fb.group(this.getQuestionConfig),
      answer: [null, getRequiredValidator(required)],
      upload: [null, getRequiredValidator(required)],
      comment: [null],
      commentHint: [null],
      commentAllowed: [null],
      constantName: [null],
      showSelfDisclosureQuestions: [null],
      mandatory: [null],
      title: [null],
      type: [null],
      id: [null]
    };
  }

  public getQuestionConfig() {
    return {
      id: [''],
      title: [''],
      type: [''],
      options: [[]],
      mandatory: [false],
      showSelfDisclosureQuestions: [true],
      commentAllowed: [false],
      commentHint: [''],
      constantName: [null],
      uploadAllowed: [false],
      uploadHint: [''],
      upload: [null],
      subQuestions: this.fb.array([])
    };
  }

  public getConfirmationConfig(text) {
    return {
      checked: [null, Validators.requiredTrue],
      text: [text]
    };
  }

  public getConfigForSubQuestion(type: string, mandatory: boolean) {
    if (type === SelfDisclosureQuestionType.ADDRESS) {
      return this.getAddressConfig(mandatory);
    } else if (type === SelfDisclosureQuestionType.EMPLOYMENT) {
      return this.getEmploymentConfig(mandatory);
    } else if (
      type === SelfDisclosureQuestionType.BOOLEAN ||
      type === SelfDisclosureQuestionType.SELECT
    ) {
      return this.getBooleanSelectConfig(mandatory);
    } else if (type === SelfDisclosureQuestionType.DOCUMENT) {
      return this.getDocumentConfig(mandatory);
    } else if (type === SelfDisclosureQuestionType.IBAN) {
      return this.getIbanQuestionConfig(mandatory);
    } else if (type === SelfDisclosureQuestionType.TAX_ID) {
      return this.getTaxIdQuestionConfig(mandatory);
    } else {
      return this.getSubQuestionConfig(mandatory);
    }
  }

  private getById(
    array: SelfDisclosureQuestion[],
    id: string
  ): SelfDisclosureQuestion {
    return (array || []).find(
      element => element.answer && element.id === id.toString()
    );
  }

  private getQuestion(question: SelfDisclosureQuestion) {
    return {
      ...question,
      subQuestions: question.subQuestions || []
    };
  }

  private isRequired(question: SelfDisclosureQuestion) {
    return question.mandatory && question.showSelfDisclosureQuestions;
  }

  public getDocumentsForUpload(questions: SelfDisclosureQuestion[]) {
    const nestedDocumentsToUpload = [];
    /**
     * Here we create the mapping of uploaded documents and the place they are saved:
     * documents can be nested withing the payload. Documents are on root of payload (documentsToUpload),
     * within questions, within subQuestions of questions and also on subQuestions of person lists.
     * In the end we need to replace the uploaded file with the response from the filer (upload call).
     *
     * We save the reference in parentObject, so we can update the upload property later on.
     */
    questions.forEach(question => {
      if (
        question.answer &&
        question.type === SelfDisclosureQuestionType.PERSONS
      ) {
        // persons need special treatment: they are saved within the answer section and may have documents
        // in their subQuestions. We need to be able to find the document after the upload.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        question.answer.forEach(person => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          person.subQuestions
            .filter(
              element => element.upload && this.isFileForUpload(element.upload)
            )
            .forEach(subQuestion => {
              nestedDocumentsToUpload.push({
                parentObject: subQuestion,
                file: subQuestion.upload
              });
            });
        });
      } else if (question.upload && this.isFileForUpload(question.upload)) {
        nestedDocumentsToUpload.push({
          parentObject: question,
          file: question.upload
        });
      }
      if (question.subQuestions && question.subQuestions.length) {
        question.subQuestions
          .filter(
            element => element.upload && this.isFileForUpload(element.upload)
          )
          .forEach(subQuestion => {
            nestedDocumentsToUpload.push({
              parentObject: subQuestion,
              file: subQuestion.upload
            });
          });
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return nestedDocumentsToUpload;
  }

  public getIncomeDocument(answers: SelfDisclosureQuestion[]) {
    return this.getDocumentFromAnswers(
      answers,
      'SELF_DISCLOSURE_DOCUMENT_INCOME'
    );
  }

  public getCreditRatingDocument(answers: SelfDisclosureQuestion[]) {
    return this.getDocumentFromAnswers(
      answers,
      'SELF_DISCLOSURE_DOCUMENT_CREDIT_RATING'
    );
  }

  private getDocumentFromAnswers(
    answers: SelfDisclosureQuestion[],
    title: string
  ) {
    const personalInfoQuestion = answers.find(
      element => element.title === 'SELF_DISCLOSURE_PERSONAL_INFO_L'
    );
    if (
      personalInfoQuestion &&
      personalInfoQuestion.subQuestions &&
      personalInfoQuestion.subQuestions.length
    ) {
      const tmp = personalInfoQuestion.subQuestions.find(
        element => element.title === title
      );
      return tmp ? tmp.upload : null;
    }
    return null;
  }

  private isFileForUpload(attachment: Attachment) {
    return attachment && attachment.file instanceof Blob;
  }
}

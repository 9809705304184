<div class="card">
  <div class="card-body">
    <div class="profile__top-section">
      <div
        class="profile-address"
        [class.transparent]="!isProfileActiveControl.value || isProfileOutdated"
      >
        <div class="profile-name__header d-flex flex-wrap">
          @if (isProjectSearchProfile) {
            <app-badge class="mr5" [color]="badgeColor.PRIMARY_ACCENT_DARK">
              {{ 'project_l' | translate }}
            </app-badge>
          }
          <app-badge class="mr5" [color]="badgeColor.NEUTRAL">
            {{ 'FLATTYPES_' + searchProfile()?.propertyType | translate }}
          </app-badge>
          @if (searchProfile()?.createdByPS) {
            <app-badge class="mr5" [color]="badgeColor.PRIMARY_ACCENT_LIGHT">
              {{ 'internal_list.sp_created_by_ps.l' | translate }}
            </app-badge>
          }
          @if (showMarketingTypeBadge()) {
            <app-badge class="mr5" [color]="badgeColor.SECONDARY_ACCENT">
              {{
                'MARKETINGTYPES_' + searchProfile()?.marketingType | translate
              }}
            </app-badge>
          }
          @if (searchProfile()?.searchingSince) {
            <app-badge [color]="badgeColor.LIGHT_BLUE">
              {{ 'general.search_profile_searching_since_date_l' | translate }}
              {{ searchProfile()?.searchingSince | appDateTime }}
            </app-badge>
          }
        </div>

        {{
          searchProfile().name ||
            ('search_profile.name_l' | translate: { city: searchProfileCities })
        }}
        @if (searchProfile().type === typeRadius && !small()) {
          <div class="profile-address__details">
            {{ searchProfile().address | appAddress }}
          </div>
        }
        @if (searchProfile().type === typeDistrict && !small()) {
          <div class="profile-address__details">
            @for (district of districtsMap | keyvalue; track district) {
              <div class="profile-address__details--cities">
                <span>{{ district.key }}</span>
                <span>
                  ({{
                    getMappedDistrictNames(district.value | appSort: 'name')
                  }})
                </span>
              </div>
            }
          </div>
        }
        @if (searchProfile()?.projectLandingPageUrl) {
          <div class="d-flex align-items-center">
            <div class="icon icon--export me-2"></div>
            <a
              rel="noopener noreferrer"
              [href]="searchProfile()?.projectLandingPageUrl"
              target="_blank"
            >
              {{ 'search_profile.project_landing_page_url_l' | translate }}
            </a>
          </div>
        }
      </div>

      @if (!hideMenu()) {
        <div class="profile-menu">
          @if (showToggleOption() && !isProfileOutdated) {
            <app-slide-toggle
              class="mt5"
              [formControl]="isProfileActiveControl"
              (change)="onToggleProfile()"
              [ngbTooltip]="
                (isProfileActiveControl.value
                  ? 'search_profile.active_tooltip_l'
                  : 'search_profile.inactive_tooltip_l'
                ) | translate
              "
              tooltipClass="hide-on-mobile"
            ></app-slide-toggle>
          }
          @if (isProfileOutdated) {
            <span class="outdated-profile">{{
              'search_profile.outdated_l' | translate
            }}</span>
          }
          @if (!searchProfile()?.createdByPS) {
            <app-context-menu class="ms-1">
              <div menu-content>
                <app-context-menu-item (clickEvent)="onEditProfile()"
                  >{{ 'search_profile.edit_a' | translate }}
                </app-context-menu-item>
                <app-context-menu-item (clickEvent)="onDeleteProfile()"
                  >{{ 'search_profile.delete_a' | translate }}
                </app-context-menu-item>
              </div>
            </app-context-menu>
          }
        </div>
      }
    </div>

    <div class="section__subdivider"></div>

    <div
      class="profile-details search-profile__details d-flex"
      [class.transparent]="!isProfileActiveControl.value || isProfileOutdated"
    >
      <div
        class="profile-details__info rent d-flex align-items-center"
        [class.order-1]="!small()"
        [class.order-4]="small()"
      >
        @if (isInfinity) {
          {{ 'general.infinity_value_l' | translate }}
        } @else {
          @if (!small()) {
            <span>max&nbsp;</span>
          }
          {{
            ((!isSalesObject ? upperBoundRent : priceUpperBoundSales)
              | currency: 'EUR' : 'symbol' : '1.0-0') || '-'
          }}
        }
        @if (small() && !isSalesObject) {
          <span> / {{ 'general.month_l' | translate }}</span>
        }
      </div>

      @if (isFlat || isCommercial) {
        <div
          class="profile-details__info d-flex align-items-center order-2 pe-3 pe-md-0"
        >
          <svg-icon
            src="/assets/images/icons/icon-area.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 15 }"
            class="secondary-svg-color rect line polyline path mr5"
          ></svg-icon>
          {{
            {
              lowerBound: searchProfile().lowerBoundSize,
              upperBound: searchProfile().upperBoundSize,
              suffix: 'm²'
            } | bounds: !small()
          }}
        </div>
      }
      @if (isFlat) {
        <div class="profile-details__info d-flex align-items-center order-3">
          <svg-icon
            src="/assets/images/icons/icon-rooms.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 15 }"
            class="secondary-svg-color rect line polyline path mr5"
          ></svg-icon>
          {{
            {
              lowerBound: searchProfile().lowerBoundRooms,
              upperBound: searchProfile().upperBoundRooms,
              suffix:
                (searchProfile().lowerBoundRooms === 1
                  ? 'general.room_l'
                  : 'general.rooms_l'
                ) | translate
            } | bounds: !small()
          }}
        </div>
      }
    </div>
  </div>
</div>

import {
  Component,
  inject,
  input,
  OnInit,
  output,
  TemplateRef
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  AvailableLanguageCodesEnum,
  ContactTag,
  EditTaskType,
  HierarchicalQuestionModel,
  RootQuestionContainerModel
} from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { HierarchicalQuestionsDisplayQuestionComponent } from '../hierarchical-questions-display-question/hierarchical-questions-display-question.component';
import { CardComponent } from '../../../atoms/card/card.component';
import { HierarchicalQuestionService } from '../../../../services/hierarchical-question.service';

@Component({
  selector: 'app-hierarchical-questions-display-root',
  templateUrl: './hierarchical-questions-display-root.component.html',
  styleUrls: ['./hierarchical-questions-display-root.component.scss'],
  imports: [
    CardComponent,
    HierarchicalQuestionsDisplayQuestionComponent,
    AsyncPipe,
    TranslateModule
  ]
})
export class HierarchicalQuestionsDisplayRootComponent implements OnInit {
  private hierarchicalQuestionService = inject(HierarchicalQuestionService);

  readonly hierarchicalQuestionContainer =
    input<RootQuestionContainerModel>(undefined);
  readonly tags = input<ContactTag[]>(undefined);
  readonly isProcessing = input<boolean>(undefined);
  readonly appCheckTemplateRef = input<TemplateRef<any>>(undefined);
  readonly editTaskType = input<EditTaskType>(undefined);
  readonly showOnlyDeleteAction = input(false);

  readonly edit = output<RootQuestionContainerModel>();
  readonly delete = output<RootQuestionContainerModel>();

  public mainQuestion: HierarchicalQuestionModel;
  public showDetails = false;
  public languageCode$: Observable<AvailableLanguageCodesEnum>;

  public get questions() {
    return this.hierarchicalQuestionContainer().rootQuestion.questions;
  }

  public get subQuestions() {
    return this.questions
      .filter(question => question.id !== this.mainQuestion.id)
      .sort(this.hierarchicalQuestionService.sort);
  }

  ngOnInit(): void {
    this.mainQuestion = this.questions.find(
      question =>
        question.id ===
        this.hierarchicalQuestionContainer().rootQuestion.mainQuestionId
    );
    this.languageCode$ = this.hierarchicalQuestionService.getLanguageCode();
  }

  public toggleShowDetails() {
    this.showDetails = !this.showDetails;
  }

  public onEdit() {
    this.edit.emit(this.hierarchicalQuestionContainer());
  }

  public onDelete() {
    this.delete.emit(this.hierarchicalQuestionContainer());
  }
}

@if (scrollable()) {
  <app-infinite-scroll
    [style.max-height]="maxScrollableHeight()"
    (scrolled)="onScrollChange()"
  >
    <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
  </app-infinite-scroll>
} @else {
  <table
    class="table"
    [ngClass]="{
      'table-bordered': borderedTable(),
      'table-dark': darkTable(),
      'table-striped': stripedTable(),
      'table-hover': hoveredTable(),
      'table-sm': smallTable(),
      'table-responsive': responsiveTable()
    }"
  >
    <thead
      [ngClass]="{
        'thead-dark': darkHeader(),
        'thead-light': lightHeader()
      }"
    >
      <tr>
        @if (showIndex()) {
          <th scope="col">#</th>
        }
        @for (headColumn of headColumns(); track headColumn.name) {
          <th scope="col">{{ headColumn.label | translate }}</th>
        }
      </tr>
    </thead>
    <tbody>
      @if (data()?.length) {
        <ng-container [ngTemplateOutlet]="rowTemplate"></ng-container>
      } @else {
        <tr class="no-content-row">
          <td
            class="text-center default-grey-s"
            [attr.colspan]="headColumns()?.length"
          >
            {{ noDataMessage() | translate }}
          </td>
        </tr>
      }
    </tbody>
    @if (data()?.length) {
      <tfoot>
        <tr>
          <td [attr.colspan]="headColumns()?.length"></td>
        </tr>
      </tfoot>
    }
    @if (isLoading()) {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }
  </table>
}

<ng-template #tableTemplate>
  <table
    class="table"
    [ngClass]="{
      'table-bordered': borderedTable(),
      'table-dark': darkTable(),
      'table-striped': stripedTable(),
      'table-hover': hoveredTable(),
      'table-sm': smallTable(),
      'table-responsive': responsiveTable()
    }"
  >
    <thead
      [ngClass]="{
        'thead-dark': darkHeader(),
        'thead-light': lightHeader()
      }"
    >
      <tr>
        @if (showIndex()) {
          <th scope="col">#</th>
        }
        @for (headColumn of headColumns(); track headColumn) {
          <th scope="col">{{ headColumn.label | translate }}</th>
        }
      </tr>
    </thead>
    <tbody>
      @if (data()?.length) {
        <ng-container [ngTemplateOutlet]="rowTemplate"></ng-container>
      } @else {
        <tr class="no-content-row">
          <td
            class="text-center default-grey-s"
            [attr.colspan]="headColumns()?.length"
          >
            {{ noDataMessage() | translate }}
          </td>
        </tr>
      }
    </tbody>

    @if (data()?.length) {
      <tfoot>
        <tr>
          <td [attr.colspan]="headColumns()?.length"></td>
        </tr>
      </tfoot>
    }
    @if (isLoading()) {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }
  </table>
</ng-template>

<ng-template #rowTemplate>
  @for (item of data(); track item[uniqueIdentifier()]; let i = $index) {
    <tr>
      @if (showIndex()) {
        <td>{{ i + 1 }}</td>
      }
      @for (headColumn of headColumns(); track headColumn) {
        <td>
          @if (cellTemplatesMap[headColumn.name]) {
            <ng-container
              [ngTemplateOutlet]="cellTemplatesMap[headColumn.name]"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
          } @else {
            {{ item[headColumn.name] || '-' }}
          }
        </td>
      }
    </tr>
  }
</ng-template>

<ng-template #loading>
  <div class="loading-spinner">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>

<div
  appElevation
  #switchContainer
  [ngClass]="{
    'fade-left': leftFade,
    'fade-right': rightFade,
    'switch-container': enableScrollFade(),
    semibold: fontSemibold()
  }"
  (scroll)="enableScrollFade() && onHorizontalScroll()"
  (wheel)="enableScrollFade() && onWheelScroll($event)"
  [elevation]="enableScrollFade() && elevation()"
>
  <div
    appElevation
    [elevation]="!enableScrollFade() && elevation()"
    class="switch"
    [ngClass]="{ 'switch--disabled': disabled }"
    [class]="'switch--size-' + size()"
  >
    @for (
      item of config();
      track item[itemValueKey()];
      let i = $index;
      let isLast = $last
    ) {
      <div
        [ngClass]="{
          'd-flex align-items-center': true,
          switch__container: !enableDivider()
        }"
      >
        @if (!item.invisible) {
          <div
            class="switch__item"
            [ngClass]="{
              'switch__item--active': selectedItem() === item[itemValueKey()],
              'switch__item--disabled': item.disabled
            }"
            [ngbTooltip]="(item.disabled ? item.tooltip : null) | translate"
            (click)="selectItem(item)"
          >
            @if (item.label) {
              <span> {{ item.label | translate }} </span>
            }
            @if (item.amount !== undefined) {
              <app-badge
                [ngClass]="{ 'ms-2': item.label }"
                [color]="
                  item[itemValueKey()] === selectedItem()
                    ? BadgeColorEnum.NEUTRAL_LIGHT
                    : BadgeColorEnum.NEUTRAL_LIGHT_DARK
                "
                [fontBold]="fontSemibold()"
              >
                @if (showLoadingIndicatorForCount()) {
                  <div class="skeleton"></div>
                } @else {
                  {{ item.amount }}
                }
              </app-badge>
            }
          </div>
          @if (!isLast && enableDivider()) {
            <div class="switch__divider"></div>
          }
        }
      </div>
    }
  </div>
</div>

<select
  class="form-control styled-select"
  [ngClass]="{ 'shared-model-active': shouldAddSharedModelActiveClass() }"
  [disabled]="disabled || readonly()"
  [required]="required()"
  [(ngModel)]="value"
  [id]="id()"
>
  @if (valueNotAItem && showAdditionalItem()) {
    <option>{{ additionalItem.label | translate }}</option>
  }
  @if (placeholder()) {
    <option [ngValue]="null" selected>{{ placeholder() }}</option>
  }
  @for (o of options; track o) {
    <option [ngValue]="o.value">{{ o.label | translate }}</option>
  }
</select>

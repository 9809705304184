import {
  ChangeDetectionStrategy,
  Component,
  contentChild,
  forwardRef,
  inject,
  input,
  model,
  OnChanges,
  OnInit,
  output,
  signal,
  SimpleChanges,
  TemplateRef,
  viewChild
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';

import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  NgbDropdown,
  NgbDropdownAnchor,
  NgbDropdownMenu
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { FormControlPipe } from '../../../pipes';
import { CheckComponent } from '../../legacy/form/controls/check/check.component';
import { InfiniteScrollComponent } from '../infinite-scroll/infinite-scroll.component';
import { LoadingSpinnerComponent } from '../../legacy/loading-spinner/loading-spinner.component';
import { AppInputDirective } from '../../legacy/form/controls/input';
import {
  arrayHasPrimitiveValues,
  removeArrayDuplicates,
  removeArrayDuplicatesPrimitive
} from '../../../utils';
import { BaseControl } from '../../legacy/form/controls/base-control';
import { AppFormFieldControl } from '../../legacy/form/form-field/form-field-control/form-field-control';

@UntilDestroy()
@Component({
  selector: 'app-multi-select-dropdown-v2',
  templateUrl: './multi-select-dropdown-v2.component.html',
  styleUrls: ['./multi-select-dropdown-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectDropdownV2Component),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => MultiSelectDropdownV2Component)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgbDropdown,
    NgbDropdownAnchor,
    AppInputDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    SvgIconComponent,
    LoadingSpinnerComponent,
    NgbDropdownMenu,
    InfiniteScrollComponent,
    NgTemplateOutlet,
    CheckComponent,
    TranslateModule,
    FormControlPipe
  ]
})
export class MultiSelectDropdownV2Component
  extends BaseControl<unknown[]>
  implements OnInit, OnChanges
{
  private fb = inject(FormBuilder);

  readonly items = model<unknown[]>(undefined);
  readonly isLoadingMenuItems = input<boolean>(undefined);
  readonly relativelyPositioned = input<boolean>(undefined);
  readonly itemValueKey = input('value');
  readonly searchCharactersLimit = input(3);
  readonly useValue = input<boolean>(undefined);
  readonly disableSearch = input<boolean>(undefined);
  readonly hideRemoveAllIcon = input<boolean>(undefined);
  readonly searchChange = output<string>();
  readonly scrollChange = output();
  readonly selectionChange = output<any[]>();
  readonly dropdown = viewChild(NgbDropdown);
  readonly templateRef = contentChild(TemplateRef);
  readonly sortedItems = signal<unknown[]>(undefined);

  public formRecord = this.fb.record<boolean>({});
  public searchControl = this.fb.control('');
  public formGroupSelectedCount = signal<number>(0);
  private allItems: unknown[];
  private applyClicked = false;

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(value => value.length >= this.searchCharactersLimit()),
        untilDestroyed(this)
      )
      .subscribe(value => this.searchChange.emit(value));

    this.dropdown()
      .openChange.pipe(
        filter(value => !value),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (!this.applyClicked) {
          this.clearSelectedControlWithoutSave();
        }
        if (this.searchControl.value !== '') {
          this.searchControl.patchValue('');
        }
        this.applyClicked = false;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.items) {
      const concatenatedItems: unknown[] = (this.allItems || []).concat(
        this.items()
      );
      this.updateAllItems(concatenatedItems);
      this.adjustFormGroupControls();

      this.sortItems();
    }
  }

  private updateAllItems(concatenatedItems: unknown[]) {
    this.allItems = arrayHasPrimitiveValues(this.items())
      ? removeArrayDuplicatesPrimitive(concatenatedItems)
      : removeArrayDuplicates(concatenatedItems, this.itemValueKey());
  }

  writeValue(value: unknown[]): void {
    if (!value) return;
    const valueToWrite = value?.map(v =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      this.useValue() || typeof v === 'string' ? v : v[this.itemValueKey()]
    );
    super.writeValue(valueToWrite);
    this.patchFormRecord();
    this.updateAllItems(this.value);
    this.sortItems();
  }

  public clear() {
    this.sortedItems().forEach((item, index) =>
      this.formRecord.get(item[this.itemValueKey()]).patchValue(false, {
        emitEvent: index === this.sortedItems().length - 1
      })
    );
  }

  public apply() {
    this.applyClicked = true;
    this.dropdown().close();
    this.applyValues();
    this.sortItems();
  }

  applyValues() {
    const selectedValues = Object.keys(this.formRecord.value).filter(
      key => this.formRecord.value[key]
    );
    this.value = !this.useValue()
      ? selectedValues
      : this.allItems.filter(
          item => this.formRecord.value[item[this.itemValueKey()]]
        );
    this.formGroupSelectedCount.set(
      Object.values(this.formRecord.value).filter(v => v).length
    );
    this.selectionChange.emit(this.value);
  }

  public onScroll() {
    this.scrollChange.emit();
  }

  public clearAndApply() {
    const groupValues: Record<string, boolean> = this.formRecord.value;
    Object.keys(groupValues).forEach(key => (groupValues[key] = false));
    this.formRecord.patchValue(groupValues);
    this.apply();
  }

  /**
   * If the dropdown is closed but the user did not click on the apply button,
   * the form is reset to the state before the dropdown was opened
   * since the user can select/deselect items but does not confirm them
   * so we do not want to show the user "wrong" info
   */
  private clearSelectedControlWithoutSave() {
    const selectedValuesToObject = (this.value || []).reduce(
      (previousValue, currentValue) => {
        previousValue[currentValue[this.itemValueKey()]] = true;
        return previousValue;
      },
      {}
    );

    const formRecordValues = Object.keys(this.formRecord.value).reduce(
      (previousValue, currentValue) => {
        if (
          selectedValuesToObject[currentValue] === undefined &&
          this.formRecord.value[currentValue]
        ) {
          previousValue[currentValue] = false;
        }

        if (
          selectedValuesToObject[currentValue] &&
          !this.formRecord.value[currentValue]
        ) {
          previousValue[currentValue] = true;
        }

        return previousValue;
      },
      {}
    );

    if (Object.keys(formRecordValues).length > 0)
      this.formRecord.patchValue(formRecordValues);
  }

  private adjustFormGroupControls() {
    this.items().forEach((item, index) => {
      const key: string = item[this.itemValueKey()];
      if (!this.formRecord.contains(key)) {
        this.formRecord.addControl(key, this.fb.control(false), {
          emitEvent: index === this.items().length - 1
        });
      }
    });
  }

  private patchFormRecord() {
    const falseFormRecordValues = Object.keys(this.formRecord.controls).reduce<
      Record<string, boolean>
    >((previousValue, key) => {
      previousValue[key] = false;
      return previousValue;
    }, {});
    const valuesToPatch: Record<string, boolean> = {
      ...falseFormRecordValues
    };
    if (this.value.length > 0) {
      this.value.forEach(value => {
        const key: string = value[this.itemValueKey()];
        if (this.formRecord.contains(key)) {
          valuesToPatch[key] = true;
        } else {
          this.formRecord.addControl(key, this.fb.control(true), {
            emitEvent: false
          });
        }
      });
    } else {
      Object.keys(this.formRecord.controls).forEach(
        key => (valuesToPatch[key] = false)
      );
    }
    this.formRecord.patchValue(valuesToPatch);
    this.formGroupSelectedCount.set(
      Object.values(this.formRecord.value).filter(v => v).length
    );
    this.cdr.detectChanges();
  }

  private sortItems() {
    const formValue = this.formRecord.value;
    if (Object.keys(formValue).length === 0) return;

    const selectedValues =
      this.searchControl.value.length > 0 &&
      this.searchControl.value.length >= this.searchCharactersLimit()
        ? []
        : this.value;
    const array = arrayHasPrimitiveValues(this.items())
      ? removeArrayDuplicatesPrimitive([
          ...(selectedValues || []),
          ...this.items()
        ])
      : removeArrayDuplicates(
          [...(selectedValues || []), ...this.items()],
          this.itemValueKey()
        );
    const sortedItems = array.toSorted((a, b) => {
      const keyA: string = !this.useValue ? a : a[this.itemValueKey()];
      const keyB: string = !this.useValue ? b : b[this.itemValueKey()];

      if (formValue[keyA] && !formValue[keyB]) return -1;
      if (!formValue[keyA] && formValue[keyB]) return 1;
      return 0;
    });
    this.sortedItems.set(sortedItems);
  }
}

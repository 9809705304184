export enum Icons {
  Zoom = 'zoom',
  Zaps = 'zaps',
  Zap = 'zap',
  Xing = 'xing',
  Window = 'window',
  Width = 'width',
  Wbs = 'wbs',
  Water = 'water',
  WashingRoom = 'washing-room',
  WashingMachine = 'washing-machine',
  Warning = 'warning',
  UviHeating = 'uvi_heating',
  UtilityRoom = 'utility-room',
  User = 'user',
  Urinal = 'urinal',
  Upload = 'upload',
  Tv = 'tv',
  Train = 'train',
  Toilet = 'toilet',
  Ticket = 'ticket',
  TextEditor = 'text-editor',
  Terrace = 'terrace',
  Sun = 'sun',
  Store = 'store',
  StoreRoom = 'store-room',
  StarHalf = 'star-half',
  StarFilled = 'star-filled',
  StarEmpty = 'star-empty',
  Stairwell = 'stairwell',
  Stairs = 'stairs',
  SpinnerFilled = 'spinner-filled',
  SpinnerDots = 'spinner-dots',
  Sorting = 'sorting',
  Shower = 'shower',
  Share = 'share',
  Settings = 'settings',
  Senior = 'senior',
  SelfDisclosure = 'self-disclosure',
  Search = 'search',
  SearchProfile = 'search-profile',
  School = 'school',
  Save = 'save',
  Rooms = 'rooms',
  Restoration = 'restoration',
  Restaurants = 'restaurants',
  Reset = 'reset',
  Reporting = 'reporting',
  Remove = 'remove',
  Refresh = 'refresh',
  Redirect = 'redirect',
  Question = 'question',
  Publish = 'publish',
  Pool = 'pool',
  Plus = 'plus',
  Plug = 'plug',
  Planning = 'planning',
  Plane = 'plane',
  Pipe = 'pipe',
  PiggyBank = 'piggy-bank',
  PieChart = 'pie-chart',
  Phone = 'phone',
  Pet = 'pet',
  Pdf = 'pdf',
  PartyCannon = 'party-cannon',
  Parking = 'parking',
  PaperPlane = 'paper-plane',
  PaperClip = 'paper-clip',
  PaintRoller = 'paint-roller',
  PaintBrush = 'paint-brush',
  Office = 'office',
  Offer = 'offer',
  Object = 'object',
  Notes = 'notes',
  News = 'news',
  MovingBox = 'moving-box',
  MoreMoney = 'more-money',
  Monument = 'monument',
  MoneyPig = 'money-pig',
  MoneyBag = 'money-bag',
  Mobile = 'mobile',
  Minus = 'minus',
  Messenger = 'messenger',
  Masonry = 'masonry',
  Marketing = 'marketing',
  Mail = 'mail',
  Logout = 'logout',
  Loggia = 'loggia',
  LockOpen = 'lock-open',
  LockClosed = 'lock-closed',
  Location = 'location',
  LocationOutlined = 'location-outlined',
  List = 'list',
  Linkedin = 'linkedin',
  Link = 'link',
  LinesChart = 'lines-chart',
  Lightning = 'lightning',
  LightningOutlined = 'lightning-outlined',
  LightBulb = 'light-bulb',
  Lift = 'lift',
  Level = 'level',
  Law = 'law',
  Laundry = 'laundry',
  LaundryRoom = 'laundry-room',
  Kitchen = 'kitchen',
  Keyboard = 'keyboard',
  Key = 'key',
  InternetCable = 'internet-cable',
  Intercom = 'intercom',
  Info = 'info',
  HouseType = 'house-type',
  HouseDoor = 'house-door',
  Hospital = 'hospital',
  History = 'history',
  Highway = 'highway',
  HighVoltage = 'high-voltage',
  Height = 'height',
  Heating = 'heating',
  HeatingCosts = 'heating-costs',
  HealthCare = 'health-care',
  Handicapped = 'handicapped',
  Hammer = 'hammer',
  HalfRooms = 'half-rooms',
  Globe = 'globe',
  GasContainer = 'gas-container',
  Garden = 'garden',
  Garage = 'garage',
  Gallery = 'gallery',
  Furniture = 'furniture',
  Food = 'food',
  Folder = 'folder',
  FolderOutlined = 'folder-outlined',
  Floor = 'floor',
  Flashes = 'flashes',
  Fireplace = 'fireplace',
  Fire = 'fire',
  Filter = 'filter',
  Feedback = 'feedback',
  EyeOpen = 'eye-open',
  EyeClosed = 'eye-closed',
  Extinguisher = 'extinguisher',
  Export = 'export',
  Expand = 'expand',
  EnergyCertificate = 'energy-certificate',
  Elevator = 'elevator',
  Editor = 'editor',
  Edit = 'edit',
  Download = 'download',
  Door = 'door',
  Document = 'document',
  Discover = 'discover',
  DetachedLinkFilled = 'detached-link-filled',
  Delete = 'delete',
  Data = 'data',
  Dashboard = 'dashboard',
  Crown = 'crown',
  Crosshair = 'crosshair',
  Crane = 'crane',
  Copy = 'copy',
  Cookie = 'cookie',
  Contracts = 'contracts',
  Context = 'context',
  ContextMenuOutlined = 'context-menu-outlined',
  Contacts = 'contacts',
  Computer = 'computer',
  Compass = 'compass',
  Commentary = 'commentary',
  Coins = 'coins',
  Coin = 'coin',
  Close = 'close',
  CloseOutlined = 'close-outlined',
  Clock = 'clock',
  Clipboard = 'clipboard',
  ChevronUp = 'chevron-up',
  ChevronRight = 'chevron-right',
  ChevronLeft = 'chevron-left',
  ChevronDown = 'chevron-down',
  Check = 'check',
  CheckFramed = 'check-framed',
  ChatTemplate = 'chat-template',
  ChartPie = 'chart-pie',
  ChartLine = 'chart-line',
  ChartBar = 'chart-bar',
  Celebrations = 'celebrations',
  Calendar = 'calendar',
  Bus = 'bus',
  BurgerMenu = 'burger-menu',
  BurgerMenuReduce = 'burger-menu-reduce',
  BurgerMenuExpand = 'burger-menu-expand',
  Bulb = 'bulb',
  Building = 'building',
  Bubbletub = 'bubbletub',
  Board = 'board',
  Bling = 'bling',
  Blinders = 'blinders',
  Bike = 'bike',
  Bidet = 'bidet',
  Bell = 'bell',
  Bed = 'bed',
  Bathtub = 'bathtub',
  Basement = 'basement',
  BarrierFree = 'barrier-free',
  BarChart = 'bar-chart',
  Balcony = 'balcony',
  Attic = 'attic',
  Attention = 'attention',
  Assistant = 'assistant',
  ArrowUp = 'arrow-up',
  ArrowRight = 'arrow-right',
  ArrowLeft = 'arrow-left',
  ArrowDropdown = 'arrow-dropdown',
  ArrowDown = 'arrow-down',
  Armchair = 'armchair',
  AreaSize = 'area-size',
  Archive = 'archive',
  AirCondition = 'air-condition',
  Add = 'add'
}

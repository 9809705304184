import {
  ChangeDetectionStrategy,
  Component,
  input,
  model
} from '@angular/core';

@Component({
  selector: 'app-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['./form-field-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldLabelComponent {
  readonly showRequiredMarker = model(false);
  readonly idFor = model<string>(undefined);
  readonly disabled = input(false);
  readonly smallText = input(false);
  readonly redText = input(false);
  readonly noBottomMargin = input(false);
}

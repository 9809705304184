import {
  IconTypeEnum,
  MarketingType,
  MarketingWorkflowState,
  MarketingWorkflowStateFilter,
  NameValue,
  Property,
  PropertyDelegationOverviewTypes,
  PropertyStatus,
  PropertyType
} from '@ui/shared/models';
import { signal } from '@angular/core';

const nonViewableProspectStates = signal([
  MarketingWorkflowState.NEW,
  MarketingWorkflowState.SOLD,
  MarketingWorkflowState.RENTED_OUT
]);

export const isRentalProperty = (marketingType: MarketingType): boolean => {
  return marketingType === MarketingType.RENT;
};

export const isSalesProperty = (marketingType: MarketingType): boolean => {
  return marketingType === MarketingType.SALES;
};

export const isPropertyTypeFlat = (propertyType: PropertyType): boolean => {
  return propertyType === PropertyType.FLAT;
};

export const isPropertyTypeGarage = (propertyType: PropertyType): boolean => {
  return propertyType === PropertyType.GARAGE;
};

export const isPropertyTypeFlatOrGarage = (
  propertyType: PropertyType
): boolean => {
  return (
    propertyType === PropertyType.FLAT || propertyType === PropertyType.GARAGE
  );
};

export const isPropertyTypeCommercial = (
  propertyType: PropertyType
): boolean => {
  return propertyType === PropertyType.COMMERCIAL;
};

export const isPropertyTypeWithDistrict = (
  propertyType: PropertyType
): boolean => {
  return [PropertyType.COMMERCIAL, PropertyType.FLAT].includes(propertyType);
};

export const isPropertyForRent = (property: Property): boolean => {
  return property?.marketingType === MarketingType.RENT;
};

export const isPropertyForSale = (property: Property): boolean => {
  return property?.marketingType === MarketingType.SALES;
};

export const isPropertyReceived = (property: Property): boolean => {
  return (
    property?.delegationOverview?.type ===
    PropertyDelegationOverviewTypes.SENDER
  );
};

export const isPropertyDelegated = (property: Property): boolean => {
  return (
    property?.delegationOverview?.type ===
    PropertyDelegationOverviewTypes.RECEIVER
  );
};

export const isPropertyWithoutDelegation = (property: Property): boolean => {
  return !property?.delegationOverview;
};

export const isPropertyImported = (property: Property): boolean => {
  return property?.status === PropertyStatus.IMPORTED;
};
export const isPropertyReserved = (property: Property): boolean => {
  return property?.status === PropertyStatus.RESERVED;
};
export const isPropertyRentedOut = (property: Property): boolean => {
  return property?.rented;
};

export const isPropertyOffline = (property: Property): boolean => {
  return (
    // We're making sure this property wasn't published on any portal
    // If the property was published but has an error, active will still be false
    !property?.active &&
    property?.status === PropertyStatus.DEFAULT &&
    !property?.tenantInfo &&
    !property?.delegationOverview
  );
};

export const isPropertyTaskRunning = (property: Property): boolean => {
  return (
    property?.task !== 'IDLE' || !!property.asynchronousPropertyProcess?.type
  );
};

export const isContractAssignedToProperty = (property: Property): boolean => {
  return property?.hasContract;
};

export const canPropertyBePublished = (property: Property): boolean => {
  return (
    !isPropertyTaskRunning(property) &&
    !isPropertyReserved(property) &&
    !property.active &&
    !isPropertyRentedOut(property) &&
    !isPropertyDelegated(property) &&
    hasPropertyPublishingPortalsSet(property)
  );
};

export const canPropertyBeDeleted = (property: Property): boolean => {
  return (
    !isPropertyTaskRunning(property) &&
    !isContractAssignedToProperty(property) &&
    property.inactive &&
    isPropertyWithoutDelegation(property)
  );
};

export const canPropertyBeRemovedFromMarketingWorkflow = (
  property: Property
): boolean => {
  return (
    !isPropertyTaskRunning(property) &&
    isContractAssignedToProperty(property) &&
    property.inactive &&
    isPropertyWithoutDelegation(property) &&
    !!property.currentPropertyProcessBean?.marketingWorkflowState
  );
};

export const canPropertyBeDelegated = (property: Property): boolean => {
  return (
    !isPropertyTaskRunning(property) &&
    isPropertyWithoutDelegation(property) &&
    isPropertyOffline(property) &&
    !isPropertyReserved(property) &&
    !isPropertyRentedOut(property)
  );
};

export const canPropertyBeWithdrawn = (property: Property): boolean => {
  return (
    !isPropertyTaskRunning(property) &&
    isPropertyDelegated(property) &&
    property.inactive
  );
};

export const canPropertyBeReturned = (property: Property): boolean => {
  return (
    !isPropertyTaskRunning(property) &&
    isPropertyReceived(property) &&
    property.inactive
  );
};

export const canPropertyBeDeactivated = (property: Property): boolean => {
  return (
    !isPropertyTaskRunning(property) &&
    hasPropertyPublishingPortalsSet(property) &&
    property.active &&
    !isPropertyDelegated(property)
  );
};

export const canInviteApplicantToProperty = (property: Property): boolean => {
  return !isPropertyTaskRunning(property) && !isPropertyDelegated(property);
};

export const canPropertyBeReserved = (property: Property): boolean => {
  return (
    !isPropertyTaskRunning(property) &&
    !isPropertyReserved(property) &&
    !isPropertyDelegated(property) &&
    !isPropertyRentedOut(property)
  );
};

export const canPropertyBeUnreserved = (property: Property): boolean => {
  return (
    !isPropertyTaskRunning(property) &&
    isPropertyReserved(property) &&
    !isPropertyDelegated(property)
  );
};

export const hasPropertyProposals = (property: Property): boolean => {
  return !!property?.sizeOfProposals;
};

export const hasPropertyApplications = (property: Property): boolean => {
  return !!property?.sizeOfApplications;
};

export const canViewPropertyProspects = (property: Property): boolean => {
  return (
    !isPropertyTaskRunning(property) &&
    !isPropertyDelegated(property) &&
    !isPropertyImported(property) &&
    !!property?.currentPropertyProcessBean?.marketingWorkflowState &&
    !nonViewableProspectStates().includes(
      property?.currentPropertyProcessBean?.marketingWorkflowState
    )
  );
};

export const canViewPropertyApplicants = (property: Property): boolean => {
  return (
    !isPropertyTaskRunning(property) &&
    !isPropertyDelegated(property) &&
    !isPropertyImported(property) &&
    !!property?.currentPropertyProcessBean?.marketingWorkflowState && // TODO: Check
    property?.currentPropertyProcessBean?.marketingWorkflowState !== // TODO: Check
      MarketingWorkflowState.NEW
  );
};

export const hasPropertyPublishingPortalsSet = (
  property: Property
): boolean => {
  return !!property?.portals?.length;
};

export const getPropertyTypeIcon = (type: PropertyType): string => {
  if (isPropertyTypeFlat(type)) {
    return IconTypeEnum.HouseType;
  } else if (isPropertyTypeGarage(type)) {
    return IconTypeEnum.Garage;
  } else {
    return IconTypeEnum.Building;
  }
};

export const generatePropertyPhases = (
  hasSalesObjectAccess: boolean,
  showStateNew: boolean,
  showLastPhase = true
) => {
  const prefix = 'MARKETING_WORKFLOW_STATE.';
  const suffix = '_L';
  return Object.keys(MarketingWorkflowState).reduce((acc: NameValue[], key) => {
    if (key === MarketingWorkflowState.SOLD && !hasSalesObjectAccess) {
      return acc;
    }
    if (key === MarketingWorkflowState.NEW && !showStateNew) {
      /*
     NEW is filtered out in the marketing workflow state since it is not a valid state for manual change
     NEW is shown in the marketing workflow state when used in the marketing workflow state filter
    */
      return acc;
    }
    if (
      (key === MarketingWorkflowState.TENANT_APPROVAL_PHASE ||
        key === MarketingWorkflowState.SOLD ||
        key === MarketingWorkflowState.RENTED_OUT) &&
      !showLastPhase
    ) {
      return acc;
    }
    acc.push({
      name: prefix + key + suffix,
      value: MarketingWorkflowState[key]
    });
    return acc;
  }, []);
};

export const generatePropertyPhaseFilters = (
  showTenantApprovalPhase: boolean
) => {
  const prefix = 'MARKETING_WORKFLOW_STATE.';
  const suffix = '_L';

  return Object.keys(MarketingWorkflowStateFilter).reduce(
    (acc: NameValue[], key) => {
      if (
        key === MarketingWorkflowStateFilter.TENANT_APPROVAL_PHASE &&
        !showTenantApprovalPhase
      ) {
        return acc;
      }
      acc.push({
        name: prefix + key + suffix,
        value: MarketingWorkflowStateFilter[key]
      });
      return acc;
    },
    []
  );
};

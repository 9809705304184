import {
  Component,
  ElementRef,
  forwardRef,
  input,
  model,
  OnInit,
  output,
  viewChild
} from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';
import { BadgeComponent } from '../../atoms/badge/badge.component';
import {
  Elevation,
  ElevationDirective,
  ElevationType
} from '../../../directives';
import { AppFormFieldControl } from '../../legacy/form/form-field/form-field-control/form-field-control';
import { BaseControl } from '../../legacy/form/controls/base-control';
import { BadgeColorEnum } from '../../atoms/badge';
import { SwitchItemConfig, SwitchSize } from './switch.model';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SwitchComponent)
    }
  ],
  imports: [
    NgClass,
    NgbTooltip,
    BadgeComponent,
    TranslateModule,
    ElevationDirective
  ]
})
export class SwitchComponent
  extends BaseControl<string | number | boolean>
  implements OnInit
{
  readonly config = input<SwitchItemConfig[]>(undefined);
  readonly itemValueKey = input('id');
  readonly selectedItem = model<string>(undefined);
  readonly size = input<SwitchSize>(SwitchSize.NORMAL);
  readonly stretch = input(false);
  readonly enableDivider = input(false);
  readonly enableScrollFade = input(false);
  readonly elevation = input<ElevationType>(Elevation.ONE);
  readonly fontSemibold = input(false);
  readonly showLoadingIndicatorForCount = input(false);

  readonly itemSelectEvent = output<string>();
  public leftFade = false;
  public rightFade = true;
  readonly el = viewChild<ElementRef>('switchContainer');
  private _errors = null;
  private _touched = false;

  get errors() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._errors;
  }

  set errors(errors: any) {
    this._errors = errors;
  }

  get touched() {
    return this._touched;
  }

  set touched(value: boolean) {
    this._touched = value;
  }

  public ngOnInit() {
    this.value = this.config()?.find(
      item => item[this.itemValueKey()] === this.selectedItem()
    )?.value;
  }

  writeValue(value: string | number | boolean) {
    if (value) {
      this.value = value;
      this.selectedItem.set(
        this.config().find(item => item.value === this.value)?.[
          this.itemValueKey()
        ]
      );
    }
  }

  selectItem(item: SwitchItemConfig): void {
    if (
      this.disabled ||
      item.disabled ||
      item[this.itemValueKey()] === this.selectedItem()
    )
      return;
    this.itemSelectEvent.emit(item[this.itemValueKey()]);
    this.selectedItem.set(item[this.itemValueKey()]);
    this.value = item.value;
    this.touched = true;
  }

  public onHorizontalScroll(): void {
    const target = this.el().nativeElement as HTMLElement;
    const edgeTolerance = 10;
    // remove fade if scroll is near edges
    if (target.scrollLeft < edgeTolerance) {
      this.leftFade = false;
    } else if (
      target.scrollLeft >=
      target.scrollWidth - target.offsetWidth - edgeTolerance
    ) {
      this.rightFade = false;
    } else {
      this.leftFade = true;
      this.rightFade = true;
    }
  }

  public onWheelScroll(event: WheelEvent): void {
    const target = this.el().nativeElement as HTMLElement;
    if (!event.deltaY) {
      return; // keep horizontal scrolling same
    }

    // transform vertical scroll to horizontal scroll
    target.scrollLeft += event.deltaY + event.deltaX;
  }

  protected readonly BadgeColorEnum = BadgeColorEnum;
}

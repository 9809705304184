import {
  Component,
  forwardRef,
  input,
  viewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ColorPickerModule } from 'ngx-color-picker';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { AutofocusDirective } from '../../../../../directives';
import { BaseControl } from '../base-control';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => ColorPickerComponent)
    }
  ],
  imports: [ColorPickerModule, FormsModule, TranslateModule, AutofocusDirective]
})
export class ColorPickerComponent extends BaseControl<string> {
  public color: string;
  readonly title = input<string>(undefined);
  readonly autoFocus = input(false);
  readonly description = input<string>(undefined);
  readonly ngControl = viewChild(NgControl);

  public valueChange(value: string) {
    this.value = value.toUpperCase();
  }
}

export interface MailConfigCustomer {
  mailService: MailServiceType;
  senderAddressPrefix: string;
  senderNamePrefix: string;
  senderDomain: string;
}

export enum MailServiceType {
  FLOWMAILER = 'FLOWMAILER',
  SENDGRID = 'SENDGRID'
}

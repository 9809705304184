import { inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Address, Coordinates } from '@ui/shared/models';

import { filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GoogleApiConfig } from '../components/components.config';

const BASE_URL = 'https://maps.googleapis.com';
const API_URL = '/maps/api/geocode/json';

export const GOOGLE_MAPS_API_CONFIG = new InjectionToken<GoogleApiConfig>(
  'GOOGLE_MAPS_API_CONFIG'
);

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  private http = inject(HttpClient);
  private apiConfig = inject(GOOGLE_MAPS_API_CONFIG);

  private getGeocoding(address: any, language = 'de') {
    if (!address) return of(null);

    const {
      street,
      houseNumber,
      zipCode,
      city,
      country
    }: {
      street: string;
      houseNumber: string;
      zipCode: string;
      city: string;
      country: string;
    } = address;

    let formattedAddress = '';

    if (street && houseNumber) {
      formattedAddress = `${street} ${houseNumber},`;
    }

    if (zipCode) {
      formattedAddress = `${formattedAddress} ${zipCode}`;
    }

    if (city) {
      formattedAddress = `${formattedAddress} ${city}`;
    }

    if (country) {
      formattedAddress = `${formattedAddress} ${country}`;
    }

    formattedAddress = encodeURIComponent(formattedAddress);

    const path = `${BASE_URL}${API_URL}?address=${formattedAddress}&language=${language}&key=${this.apiConfig.apiKey}`;

    return this.http.get(path);
  }

  public getReversedGeocoding(
    coordinates: Coordinates,
    language = 'de'
  ): Observable<Address> {
    if (!coordinates || !coordinates.lat || !coordinates.lon)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return of(null);

    const latlng = `${coordinates.lat},${coordinates.lon}`;
    const path = `${BASE_URL}${API_URL}?latlng=${latlng}&language=${language}&key=${this.apiConfig.apiKey}`;

    return this.http
      .get(path)
      .pipe(map(result => this.processReversedAddressComponents(result)));
  }

  public getAddressComponents(address: any) {
    return this.getGeocoding(address).pipe(map(this.processAddressComponents));
  }

  public getLocation(address: any) {
    return this.getGeocoding(address).pipe(map(this.processLocation));
  }

  private processReversedAddressComponents(data: any): Address {
    if (
      data.status !== 'OK' ||
      !data.results ||
      !data.results[0] ||
      !data.results[0].address_components
    )
      return null;

    const addressComponents: AddressComponent[] =
      data.results[0].address_components;

    return {
      country: this.getAddressElementOFType(
        addressComponents,
        'country',
        'short_name'
      ),
      countryName: this.getAddressElementOFType(addressComponents, 'country'),
      region: this.getAddressElementOFType(
        addressComponents,
        'administrative_area_level_1'
      ),
      zipCode: this.getAddressElementOFType(addressComponents, 'postal_code'),
      district: this.getAddressElementOFType(addressComponents, 'district'),
      city:
        this.getAddressElementOFType(addressComponents, 'locality') ||
        this.getAddressElementOFType(addressComponents, 'postal_town'),
      street: this.getAddressElementOFType(addressComponents, 'route'),
      houseNumber: this.getAddressElementOFType(
        addressComponents,
        'street_number'
      )
    };
  }

  private getAddressElementOFType(
    components: AddressComponent[],
    type: string,
    nameType = 'long_name'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (components.find(element => element.types.includes(type)) || {})[
      nameType
    ];
  }

  private processLocation(data: any) {
    if (
      data.status !== 'OK' ||
      !data.results ||
      !data.results[0] ||
      !data.results[0].geometry
    )
      return null;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data.results[0].geometry.location;
  }

  private processAddressComponents(data: any) {
    if (
      data.status !== 'OK' ||
      !data.results ||
      !data.results[0] ||
      !data.results[0].address_components
    )
      return null;
    const fetchedAddress = {
      city: '',
      region: '',
      countryName: 'DEFAULT_COUNTRY_L',
      country: null
    };

    (data.results[0].address_components as AddressComponent[]).forEach(el => {
      fetchedAddress.city =
        el.types[0] === 'locality' || el.types[0] === 'postal_town'
          ? el.long_name
          : fetchedAddress.city;
      fetchedAddress.countryName =
        el.types[0] === 'country' ? el.long_name : fetchedAddress.countryName;
      fetchedAddress.country =
        el.types[0] === 'country' ? el.short_name : fetchedAddress.country;
      fetchedAddress.region =
        el.types[0] === 'administrative_area_level_1'
          ? el.long_name
          : fetchedAddress.region;
    });

    return fetchedAddress;
  }

  public getAddressFromZipCode(zipCode: string, country = 'Germany') {
    return this.getLocation({ zipCode, country }).pipe(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      filter(result => result && result.lat && result.lng),
      switchMap(result => {
        const coordinates = {
          lat: result.lat,
          lon: result.lng
        } as Coordinates;

        return this.getReversedGeocoding(coordinates);
      })
    );
  }
}

<div class="h-100" [ngClass]="{ 'd-flex': centerImage() }">
  <picture [ngClass]="{ 'center-picture': centerImage() }">
    @for (source of sources(); track source.srcSet.x1) {
      <source
        [attr.media]="
          getMediaQuery(source.minScreenWidth, source.maxScreenWidth)
        "
        [attr.srcset]="getSrcSet(source.srcSet)"
      />
    }
    <img
      [ngClass]="{ 'center-img': centerImage() }"
      [class]="getClassName()"
      [src]="defaultSrc()"
      [alt]="alt()"
      [attr.loading]="lazyLoading() ? 'lazy' : null"
      [style.max-height]="setMaxHeight ? maxHeightInPx() + 'px' : ''"
      itemprop="image"
      [class.data-table-cell-styles]="dataTableCellPicture()"
    />
  </picture>
</div>

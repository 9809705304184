<div class="tag default">
  <i class="icon icon--wbs me-1"></i>
  <span
    class="tag__name"
    [class.nowrap]="false"
    [class.uppercase]="enableUpperCase()"
    [ngbTooltip]="
      !disableTooltip() ? ('HOUSINGPERMISSIONTYPES_' + tag() | translate) : null
    "
    >{{ 'HOUSINGPERMISSIONTYPES_' + tag() | translate }}</span
  >
</div>

import { Attachment } from './attachment.model';
import { Address } from './address.model';
import { AvailableFrom } from './property.model';
import { HousingPermissionType } from './enums';

export interface TenantApprovalOverview extends ApprovalProcess {
  property: ApprovalProcessProperty;
  applicant: ApprovalApplicant;
}

export interface ApprovalProcessProperty {
  id: number;
  marketingType: string;
  type: string;
  externalId: string;
  name: string;
  titleImage: Attachment;
  address: Address;
  housingPermissionTypes: HousingPermissionType[];
  baseRent: number;
  totalRent: number;
  purchasePrice: number;
  size: number;
  rooms: number;
  halfRooms: number;
  floor: number;
  numberOfFloors: number;
  availableFrom: AvailableFrom;
}

export interface ApprovalApplicant {
  id: string;
  membershipNumber: string;
  firstname: string;
  name: string;
  dkApprovalLevel: string;
  portrait?: Attachment;
}

export interface TenantApprovalProcessProfileData {
  firstname: string;
  name: string;
  portrait: Attachment;
}

export interface TenantApprovalProcess {
  id: string;
  requester: { profile: TenantApprovalProcessProfileData };
  comment: string;
  approvers: TenantApproverProcess[];
  state: TenantApprovalStatus;
  requestingApproverApprovalState: TenantApprovalStatus;
}

export enum TenantApprovalStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
  AUTO_CANCELED = 'AUTO_CANCELED',
  WITHOUT = 'WITHOUT'
}

export interface ApprovalProcess {
  id: string;
  state: TenantApprovalStatus;
}

export interface TenantApproverProcess extends ApprovalProcess {
  profile: TenantApprovalProcessProfileData;
}

export interface TenantApprovalOverviewSearch {
  page?: number;
  size?: number;
  sortBy: string;
  sortOrder: 'ASC' | 'DESC';
  searchTerm?: string;
  states?: TenantApprovalStatus[];
}

import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import { ContactTag, SystemTag, TagOwnershipType } from '@ui/shared/models';
import { NgClass } from '@angular/common';
import { IsItemInArrayPipe } from '../../../../pipes/is-item-in-array.pipe';
import { TagComponent } from '../../tag/tag.component';

@Component({
  selector: 'app-label-list-tag',
  templateUrl: './label-list-tag.component.html',
  styleUrls: ['./label-list-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TagComponent, NgClass, IsItemInArrayPipe]
})
export class LabelListTagComponent {
  readonly tag = input<ContactTag | SystemTag>(undefined);
  readonly name = input<string>(undefined);
  readonly tagOwnershipType = input<TagOwnershipType>(undefined);
  readonly isSystemTag = input(false);
  readonly tagType = input<TagOwnershipType>(undefined);
  readonly readonly = input(false);

  readonly unassignTag = output<ContactTag | SystemTag>();

  protected readonly SystemTag = SystemTag;
  protected readonly TagOwnershipType = TagOwnershipType;
}

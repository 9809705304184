import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';

import {
  Elevation,
  ElevationDirective,
  ElevationType,
  RippleDirective
} from '../../../directives';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from '../../../utils';
import {
  CardBorderStyleEnum,
  CardPaddingEnum,
  CardTypeEnum
} from './card.enum';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ElevationDirective, RippleDirective],
  host: {
    '(keydown.enter)': 'enterPress($event)',
    '[attr.tabindex]': 'clickable() ? 0 : undefined'
  }
})
export class CardComponent {
  readonly type = input(CardTypeEnum.DEFAULT);
  readonly borderStyle = input(CardBorderStyleEnum.DEFAULT);
  readonly elevation = input<ElevationType>(Elevation.ZERO);
  readonly padding = input<CardPaddingEnum>(CardPaddingEnum.DEFAULT);
  readonly clickable = input(false);
  readonly borderRadius = input(true);
  readonly disabled = input(false);
  readonly overflowVisible = input(false);

  readonly clickEvent = output();

  public rippleColor: string;

  public baseClass = 'card';

  public click(): void {
    this.clickEvent.emit();
  }

  public enterPress(event: KeyboardEvent) {
    // checking if eventPhase is AT_TARGET (2), which means that clicked
    // element which is emitting the event is app-card
    // and not bubbling up from child component
    if (this.clickable() && event.eventPhase === 2) this.clickEvent.emit();
  }

  public getClassName(): string {
    this.rippleColor =
      this.type() === CardTypeEnum.DEFAULT
        ? 'rgba(235, 242, 255, 0.50)'
        : 'rgba(255, 255, 255, 0.50)';

    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      [`type-${this.type()}`]: !!this.type(),
      [`border-style-${this.borderStyle()}`]: !!this.borderStyle(),
      [`border-radius-none`]: !this.borderRadius(),
      [`padding-${this.padding()}`]: !!this.padding(),
      [`overflow-visible`]: this.overflowVisible(),
      clickable: this.clickable(),
      disabled: this.disabled()
    });
  }
}

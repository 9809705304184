import { Component, forwardRef, inject, input, OnInit } from '@angular/core';
import { PortalConfig, PortalCredential } from '@ui/shared/models';
import {
  FormBuilder,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';

import { AppInputDirective } from '../../../components/legacy/form/controls/input/input.directive';
import { FormFieldComponent } from '../../../components/legacy/form/form-field/form-field.component';
import { FormFieldLabelComponent } from '../../../components/legacy/form/form-field/form-field-label/form-field-label.component';
import { CredentialValidatorService } from '../../services';
import { BasePortalCredentialControl } from '../../controls';

@UntilDestroy()
@Component({
  selector: 'app-portal-credential-details-with-provider',
  templateUrl: './portal-credential-details-with-provider.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => PortalCredentialDetailsWithProviderComponent
      ),
      multi: true
    }
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormFieldLabelComponent,
    FormFieldComponent,
    AppInputDirective
  ]
})
export class PortalCredentialDetailsWithProviderComponent
  extends BasePortalCredentialControl
  implements OnInit
{
  private fb = inject(FormBuilder);
  private credentialValidatorService = inject(CredentialValidatorService);

  public readonly portalConfig = input<PortalConfig>(undefined);
  public readonly credential = input<PortalCredential>(undefined);

  constructor() {
    super();

    this.form = this.fb.group({
      name: ['', Validators.required],
      properties: this.fb.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
        providerNumber: ['', Validators.required]
      })
    });
  }

  public ngOnInit() {
    const credentialValue = this.credential();
    if (credentialValue) {
      this.form.patchValue(credentialValue);
    }

    this.form.valueChanges.subscribe(credential => {
      this.value = { ...credential };

      this.onChange(this.value);
      this.onTouch();
    });

    this.credentialValidatorService.validationError$
      .pipe(
        filter(portal => !!portal),
        untilDestroyed(this)
      )
      .subscribe(portal =>
        this.setCredentialValidationError(
          this.portalConfig().validationControl || 'password',
          portal === this.credential().portal
        )
      );
  }
}

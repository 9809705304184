import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
  TemplateRef,
  input,
  output,
  viewChild,
  contentChild,
  model
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule
} from '@angular/forms';
import {
  NgbDropdown,
  NgbDropdownAnchor,
  NgbDropdownMenu
} from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { InfiniteScrollComponent } from '../infinite-scroll/infinite-scroll.component';
import { LoadingSpinnerComponent } from '../../legacy/loading-spinner/loading-spinner.component';
import { AppInputDirective } from '../../legacy/form/controls/input/input.directive';
import { BaseControl } from '../../legacy/form/controls/base-control';
import { AppFormFieldControl } from '../../legacy/form/form-field/form-field-control/form-field-control';

@UntilDestroy()
@Component({
  selector: 'app-single-select-dropdown',
  templateUrl: './single-select-dropdown.component.html',
  styleUrls: ['./single-select-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleSelectDropdownComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SingleSelectDropdownComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgbDropdown,
    FormsModule,
    NgbDropdownAnchor,
    NgClass,
    AppInputDirective,
    ReactiveFormsModule,
    SvgIconComponent,
    LoadingSpinnerComponent,
    NgbDropdownMenu,
    InfiniteScrollComponent,
    NgTemplateOutlet,
    TranslateModule
  ]
})
export class SingleSelectDropdownComponent
  extends BaseControl<unknown>
  implements OnInit, AfterViewInit
{
  readonly items = model<unknown[]>(undefined);
  readonly placeholderSelectedItemKey = input('name');
  readonly itemValueKey = input('value');
  readonly searchCharactersLimit = input(3);
  readonly relativelyPositioned = input<boolean>(undefined);
  readonly useValue = input<boolean>(undefined);
  readonly isLoadingMenuItems = input<boolean>(undefined);
  readonly disableSearch = input<boolean>(undefined);
  readonly searchChange = output<string>();
  readonly selectionChange = output<any>();
  readonly scrollChange = output<string>();
  readonly dropdown = viewChild(NgbDropdown);
  readonly templateRef = contentChild(TemplateRef);
  readonly ngControl = viewChild(NgControl);

  public searchControl: FormControl<string> = new FormControl<string>('');
  public selectedItem: unknown;

  ngOnInit(): void {
    this.ngControl()
      .statusChanges.pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());

    this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(value => value.length >= this.searchCharactersLimit()),
        untilDestroyed(this)
      )
      .subscribe(value => this.searchChange.emit(value));
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  public apply(item: unknown) {
    this.dropdown().close();
    this.value = this.useValue() ? item : item[this.itemValueKey()];
    this.selectedItem = item;
    this.selectionChange.emit(item);
    this.searchControl.patchValue('');

    this.updateItems();
  }

  public removeSelectedItem(): void {
    this.value = this.selectedItem = null;
    this.selectionChange.emit(this.value);
    this.touch();
  }

  public onScroll() {
    this.scrollChange.emit(this.searchControl.value);
  }

  writeValue(value: unknown): void {
    if (!value) {
      return;
    }
    const valueToWrite: unknown = value[this.itemValueKey()] ?? value;
    this.selectedItem = value;
    // if value already set on init, select correct item
    if (!this.useValue() && typeof this.selectedItem !== 'object') {
      const itemFromCollection = this.items().find(
        item => item[this.itemValueKey()] === value
      );
      if (itemFromCollection) {
        this.selectedItem = itemFromCollection;
      }
    }
    super.writeValue(valueToWrite);
  }

  private updateItems() {
    if (this.searchCharactersLimit() > 0) this.items.set([]);
  }
}

import { Attachment } from './attachment.model';
import { FilterOptions } from './gql-model';
import { Property } from './property.model';
import { Pagination } from './data-access';

type TicketPerson = {
  id: string;
  fullName: string;
};
type TicketContract = {
  id: string;
  contractNumber: string;
};
export type CommonTicketData = {
  id: string;
  unread: boolean;
  title: string;
  status: TicketStatus;
  createdOn: string | Date;
  updatedOn: string | Date;
  dueDate: string | Date;
};

export type CategoryResponse = {
  id: string;
  question: string;
  answer: string;
};

export type MetadataCategoryResponse = {
  index: number;
  questionText: string;
  response: string;
};

type DetailResponseData = {
  type: TicketAnswerType;
  response?: string | Date | Attachment[];
  answerIds?: string[];
};

type DetailResponseQuestion = {
  title: string;
};

export type DetailResponse = {
  question: DetailResponseQuestion;
  data: DetailResponseData;
  multiline: boolean;
};

export type FormResponse = {
  index: number;
  questionText: string;
  type: TicketFormResponseType;
  responses: Array<string | Date>;
  attachments: Attachment[];
};

export type TicketOverview = CommonTicketData & {
  requesterName: string;
  contractNumber: string;
  assigneeName: string;
};

export type SelectedTicket = CommonTicketData & {
  issueType: TicketIssueType;
  requester: TicketPerson;
  contract: TicketContract;
  assignee: TicketPerson;
  property: Property;
  categoryResponses: CategoryResponse[];
  detailResponses: DetailResponse[];
  metadataCategoryResponses: MetadataCategoryResponse[];
  formResponses: FormResponse[];
  conversationId: string;
  unreadMessagesCount: number;
};

export type ContractSearch = {
  id: string;
  externalId: string;
  contacts: ContactName[];
};

export type ContactName = {
  id: string;
  fullName: string;
};

export type TicketInput = {
  status: TicketStatus;
  assigneeId: string;
  dueDate?: Date;
};

export type TicketCategoryResponse = {
  nodes: TicketCategory[];
  page: Pagination;
};

export type TicketCategory = {
  id: string;
  publicId: string;
  name: string;
};

export enum TicketAttachmentDocumentType {
  IMG = 'IMG',
  PDF = 'PDF'
}

export enum TicketStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_OTHERS = 'WAITING_FOR_OTHERS',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED'
}

export enum TicketStatusClass {
  OPEN = 'ticket-status-open',
  IN_PROGRESS = 'ticket-status-in-progress',
  WAITING_FOR_OTHERS = 'ticket-status-waiting-for-others',
  CLOSED = 'ticket-status-closed',
  CANCELLED = 'ticket-status-cancelled'
}

export enum TicketStatusLabel {
  OPEN = 'ticket_table.status.open_l',
  IN_PROGRESS = 'ticket_table.status.in-progress_l',
  WAITING_FOR_OTHERS = 'ticket_table.status.waiting-for-others_l',
  CLOSED = 'ticket_table.status.closed_l',
  CANCELLED = 'ticket_table.status.cancelled_l'
}

export enum TicketIssueType {
  PROPERTY_DAMAGE = 'ticket_detail.issue_type.damage_l',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  GENERAL_DAMAGE = 'ticket_detail.issue_type.damage_l',
  CONCERN = 'ticket_detail.issue_type.concern_l'
}

export enum TicketIssueTypeValue {
  CONCERN = 'CONCERN',
  GENERAL_DAMAGE = 'DAMAGE'
}

export enum TicketNav {
  TICKETS = 'tickets'
}

export enum TicketAnswerType {
  SELECTION = 'SELECTION',
  INPUT_TEXT = 'INPUT_TEXT',
  INPUT_DATE = 'INPUT_DATE',
  INPUT_ATTACHMENTS = 'INPUT_ATTACHMENTS',
  RANGE_NUMBER = 'RANGE_NUMBER',
  RANGE_DATE = 'RANGE_DATE',
  LABEL = 'LABEL'
}

export enum TicketFormResponseType {
  LABEL = 'LABEL',
  TEXTFIELD = 'TEXTFIELD',
  TEXTBOX = 'TEXTBOX',
  DATE = 'DATE',
  ATTACHMENTS = 'ATTACHMENTS',
  SELECTION = 'SELECTION'
}

export type TicketsListOptions = FilterOptions & {
  statuses?: TicketStatus[];
  onlyAssignedToMe?: boolean;
};

export type TicketCategoryImportCSVResponse = {
  saved: number;
  totalLines: number;
  errorMessages: string[];
  errors: number;
};

export type ResponsibilityListImportCSVResponse =
  TicketCategoryImportCSVResponse;

export type LatestTicketImportCSVExists = {
  lastImportedFileExists: boolean;
};

export type TicketingVersion = 'HIERARCHICAL' | 'METADATA';

export const INACTIVE_STATUSES = [TicketStatus.CANCELLED, TicketStatus.CLOSED];

export const TICKET_STATUS_OPTIONS = [
  { name: TicketStatusLabel.OPEN, value: TicketStatus.OPEN },

  {
    name: TicketStatusLabel.IN_PROGRESS,
    value: TicketStatus.IN_PROGRESS
  },
  {
    name: TicketStatusLabel.WAITING_FOR_OTHERS,
    value: TicketStatus.WAITING_FOR_OTHERS
  },
  { name: TicketStatusLabel.CLOSED, value: TicketStatus.CLOSED }
];

export const TICKET_FILTER_STATUS_OPTIONS = [
  ...TICKET_STATUS_OPTIONS,
  { name: TicketStatusLabel.CANCELLED, value: TicketStatus.CANCELLED }
];

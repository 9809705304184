import { Component, inject, input, OnInit } from '@angular/core';
import { HomepageModule, HomepageModuleType } from '@ui/shared/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '../../../components/legacy/modal';
import { ModalContentComponent } from '../../../components/legacy/modal/modal-content/modal-content.component';
import { FormFieldLabelComponent } from '../../../components/legacy/form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../../components/legacy/form/form-field/form-field.component';
import { AppInputDirective } from '../../../components/legacy/form/controls/input';
import { DropdownSelectComponent } from '../../../components/legacy/form/controls/dropdown-select/dropdown-select.component';
import { ModalFooterComponent } from '../../../components/legacy/modal/modal-footer/modal-footer.component';
import { ButtonComponent } from '../../../components/atoms/button';

@Component({
  selector: 'app-homepage-link-modal',
  templateUrl: './homepage-link-modal.component.html',
  styleUrls: ['./homepage-link-modal.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ModalComponent,
    ModalContentComponent,
    FormFieldLabelComponent,
    FormFieldComponent,
    AppInputDirective,
    DropdownSelectComponent,
    ModalFooterComponent,
    ButtonComponent
  ]
})
export class HomepageModuleModalComponent implements OnInit {
  private ngbActiveModal = inject(NgbActiveModal);

  public link = input<HomepageModule>(undefined);
  public formGroup = new FormGroup({
    id: new FormControl(),
    name: new FormControl('', Validators.required),
    type: new FormControl(HomepageModuleType.CARD)
  });
  public homepageModuleTypes = [
    {
      value: HomepageModuleType.CARD,
      name: 'homepage_module.portal_type_tiles'
    },
    {
      value: HomepageModuleType.LIST,
      name: 'homepage_module.portal_type_table'
    }
  ];

  public ngOnInit(): void {
    if (this.link()) {
      const { id, name, type } = this.link();
      this.formGroup.patchValue({
        id,
        name,
        type
      });
    }
  }

  public save(): void {
    const { id, name, type } = this.formGroup.value as HomepageModule;
    this.ngbActiveModal.close({
      id,
      name,
      type
    });
  }

  public get typeControl(): FormControl {
    return this.formGroup.get('type') as FormControl;
  }

  public dismiss(): void {
    this.ngbActiveModal.dismiss();
  }
}

<app-card
  [class]="getClassName()"
  [elevation]="getElevation()"
  [clickable]="!pageView()"
  [padding]="'none'"
  [borderStyle]="cardBorderStyle"
  [borderRadius]="cardBorderRadius"
  [overflowVisible]="!pageView()"
  (clickEvent)="showDetailsClick()"
>
  @if (highlightCardAsActionNeeded) {
    <div
      [class]="baseClass + '__notification-bell'"
      (click)="notificationBellClick()"
    >
      <div [class]="'icon icon--bell'"></div>
    </div>
  }
  @if (!isRented()) {
    @if (showContextMenu) {
      <app-context-menu
        [class]="getContextMenuClassName()"
        [tooltipPlacement]="'bottom'"
        [buttonElevation]="getElevation() + 1"
      >
        <div menu-content>
          @if (hasCustomQuestions()) {
            <app-context-menu-item
              (clickEvent)="answerCustomQuestionsClick()"
              [iconLeft]="'question'"
            >
              {{ 'property.edit_custom_questions_a' | translate }}
            </app-context-menu-item>
          }
          @if (hasDeniedIntention()) {
            <app-context-menu-item
              (clickEvent)="declareIntentClick()"
              [iconLeft]="'check-framed'"
            >
              {{ 'property.declare_intent_a' | translate }}
            </app-context-menu-item>
          }
          @if (hasDeclaredIntention()) {
            <app-context-menu-item
              (clickEvent)="denyIntentClick()"
              [iconLeft]="'remove'"
              [iconSpacing]="true"
            >
              {{ 'property.declare_no_intent_a' | translate }}
            </app-context-menu-item>
          }
          <!-- TODO: replace attention icon with future 'no' icon and remove iconSpacing
          Before:
          <svg-icon
            [src]="'/assets/images/icons/icon-no.svg'"
            [applyClass]="true"
            [svgStyle]="{'width.px': 15}"
            class="negative-svg-color path rect circle"
          ></svg-icon>
          -->
          @if (enableExportAppointmentToCalendar()) {
            <app-context-menu-item
              (clickEvent)="exportAppointmentToCalendarClick()"
              [iconLeft]="'calendar'"
            >
              {{ 'property.export_appointment_to_calendar_a' | translate }}
            </app-context-menu-item>
          }
          @if (enableDownloadPDF()) {
            <app-context-menu-item
              (clickEvent)="downloadPDFClick()"
              [iconLeft]="'pdf'"
            >
              {{ 'property.export_pdf_a' | translate }}
            </app-context-menu-item>
          }
          <app-context-menu-item
            (clickEvent)="removePropertyClick()"
            [disabled]="isProcessing()"
            [iconLeft]="'delete'"
          >
            {{
              (isProposal()
                ? 'property.remove_property_proposal_a'
                : 'property.remove_property_application_a'
              ) | translate
            }}
          </app-context-menu-item>
          @if (isShowSelfDisclosure() && !isProposal() && isNotBlocked()) {
            <app-context-menu-item
              (clickEvent)="showSelfDisclosureClick()"
              [disabled]="isProcessing()"
              [iconSpacing]="true"
              [iconLeft]="'attention'"
            >
              {{ 'self_disclosure.answers.open_dialog_menu_a' | translate }}
            </app-context-menu-item>
          }
          @if (enableAppointmentSelection()) {
            <app-context-menu-item
              (clickEvent)="selectAppointmentClick()"
              [iconLeft]="'calendar'"
            >
              {{ 'appointment.select_appointment_a' | translate }}
            </app-context-menu-item>
          }
        </div>
      </app-context-menu>
    }
  } @else {
    @if (enableDownloadPDF()) {
      <app-button
        [class]="getDownloadPDFButtonClassName()"
        [type]="'context-menu'"
        [iconLeft]="'download'"
        [elevation]="getElevation() + 1"
        (clickEvent)="downloadPDFClick()"
      >
      </app-button>
    }
  }

  <div [class]="getBodyClassName()">
    <div [class]="baseClass + '__image-wrapper'">
      <app-image
        [class]="getImageClassName()"
        [defaultSrc]="
          hasImages
            ? images[0]?.url
            : '/assets/images/object-image-placeholder.svg'
        "
        alt="{{ hasImages && ('property.fallback_image_l' | translate) }}"
        (click)="showImageGalleryClick()"
      ></app-image>

      @if (showImageGallery) {
        <app-button
          [class]="baseClass + '__lightbox-opener'"
          [type]="'context-menu'"
          [iconLeft]="'gallery'"
          [elevation]="getElevation() + 1"
          (clickEvent)="showImageGalleryClick()"
        >
          {{ images.length }} {{ galleryButtonLabel }}
        </app-button>
      }
      @if (hasImages) {
        <div
          [class]="baseClass + '__image-background'"
          [style.background-image]="getBackgroundImage"
        ></div>
      }
    </div>
    @if (showApplicationStatusPipeline) {
      <app-application-status-pipeline
        class="d-block"
        [status]="applicationStatus()"
        [pageView]="pageView()"
        [propertyType]="propertyData()?.type"
      ></app-application-status-pipeline>
    }
    <div [class]="baseClass + '__content'" class="d-flex flex-column">
      <div
        [class]="baseClass + '__badges'"
        class="d-flex flex-row flex-wrap"
        [class.mb-3]="pageView()"
      >
        @if (propertyData().objectType || propertyData().type) {
          <app-badge>
            {{
              'FLATTYPES_' + (propertyData().objectType || propertyData().type)
                | translate
            }}
          </app-badge>
        }
        @if (propertyData().housingPermissionNeeded) {
          <app-badge [color]="'primary-accent'">
            {{ 'property.wbs_badge_l' | translate }}
          </app-badge>
        }
        @if (actionBadgeContent()) {
          <app-badge [color]="'state-900'">
            {{ actionBadgeContent() }}
          </app-badge>
        }
        @if (highlightCardAsUserIsTenant) {
          <app-badge [color]="'state-100'">
            {{ 'general.congratulations_l' | translate }}
          </app-badge>
        }
        @if (hasUnansweredQuestions() && !isRented()) {
          <app-badge [color]="'state-900'">
            {{ 'property.unanswered_questions_l' | translate }}
          </app-badge>
        }
      </div>
      <div [class]="baseClass + '__details'">
        @if (
          showExternalId() && isNotNullAndUndefined(propertyData().externalId)
        ) {
          <div [class]="baseClass + '__external-id'" class="mb-1">
            {{ 'property.external_id_l' | translate }}
            {{ propertyData().externalId }}
          </div>
        }

        <!-- Name -->
        @if (pageView()) {
          <h1
            class="title-xl d-inline-block mb-0"
            [class]="baseClass + '__title'"
          >
            {{ propertyData().name }}
          </h1>
        } @else {
          <h2
            class="title-m d-inline-block mb-0"
            [class]="baseClass + '__title'"
          >
            {{ propertyData()?.name }}
          </h2>
        }
        @if (showAddress() || pageView()) {
          <span
            class="d-block mt-1"
            [ngClass]="showAddress() && pageView() ? 'title-m' : 'title-s'"
          >
            @if (showAddress()) {
              {{ propertyData()?.address | appAddress }}
            } @else {
              {{ 'property.no_map_allowed_l' | translate }}
            }
          </span>
        }
      </div>
      @if (!pageView()) {
        <div class="mt-auto"></div>
      }
      @if (
        showAvailableFromDate() &&
        checkDateAndStringValidity(propertyData()?.availableFrom)
      ) {
        <div [class]="baseClass + '__available-from-date'" class="mt-3">
          {{ 'AVAILABLE_FROM_L' | translate }}
          <span class="ms-1">{{
            (propertyData()?.availableFrom?.dateAvailable | appDateTime) ||
              propertyData()?.availableFrom?.stringAvailable
          }}</span>
        </div>
      }
      @if (showLandlordInfo()) {
        <app-landlord-info
          [name]="landlordInfoData()?.name"
          [logo]="landlordInfoData()?.logo"
          [showInCooperationWith]="pageView()"
          [logoRedirectUrl]="
            pageView() ? landlordInfoData()?.logoRedirectUrl : null
          "
          [class.order-3]="pageView()"
          [class.mt-3]="!pageView()"
        ></app-landlord-info>
      }
      <hr [class.order-2]="pageView()" />
      <div
        [class]="baseClass + '__stats'"
        class="d-flex"
        [class.order-1]="pageView()"
        [class.mt-4]="pageView()"
      >
        <div [class]="baseClass + '__stats-left-group'">
          <!-- Room Size -->
          <div
            [class]="baseClass + '__stats-item'"
            class="d-flex align-items-center me-3"
          >
            <div [class]="'icon icon--area-size'"></div>
            {{ propertyData()?.size | number }} m<sup>2</sup>
          </div>

          <!-- Amount Rooms -->
          @if (propertyData()?.rooms) {
            <div
              [class]="baseClass + '__stats-item'"
              class="d-flex align-items-center me-3"
            >
              <div
                [class]="'icon icon--rooms'"
                [ngbTooltip]="
                  (propertyData()?.halfRooms ? 'NUMBER_OF_WHOLE_ROOMS' : '')
                    | translate
                "
              ></div>
              {{ propertyData()?.rooms | number }}
              @if (pageView()) {
                <span class="ms-1">
                  {{
                    (!propertyData()?.halfRooms
                      ? 'general.rooms_l'
                      : propertyData()?.rooms === 1
                        ? 'general.whole_room_l'
                        : 'general.whole_rooms_l'
                    ) | translate
                  }}
                </span>
              }
            </div>
          }

          <!-- Amount Half Rooms -->
          @if (propertyData()?.halfRooms) {
            <div
              [class]="baseClass + '__stats-item'"
              class="d-flex align-items-center"
            >
              <div
                [class]="'icon icon--half-rooms'"
                [ngbTooltip]="'NUMBER_OF_HALF_ROOMS_L' | translate"
              ></div>
              {{ propertyData()?.halfRooms | number }}
              @if (pageView()) {
                <span class="ms-1">
                  {{
                    (propertyData()?.halfRooms === 1
                      ? 'general.half_room_l'
                      : 'general.half_rooms_l'
                    ) | translate
                  }}
                </span>
              }
            </div>
          }
        </div>

        <!-- Price -->
        <div [class]="baseClass + '__stats-item'" class="my-0 ms-auto">
          @if (isRentalObject) {
            {{ propertyData()?.totalRentGross | currency: 'EUR' }}
            {{ 'general.monthly_short_l' | translate }}
          } @else {
            {{ propertyData()?.salesData?.price | currency: 'EUR' }}
          }
          @if (propertyData()?.commercialData?.vatNotIncludedInPrice) {
            {{ 'general.plus_vat_l' | translate }}
          }
          <!--          Implement and fix circular dependency: -->
          <!--          <app-property-price-or-rent-->
          <!--            [property]="propertyData()"-->
          <!--            [shortTranslation]="true"-->
          <!--          ></app-property-price-or-rent>-->
        </div>
      </div>
    </div>
  </div>
</app-card>

//                                                           Table update

//  In future use, default alignment (don't forget th labels, just pass alignment in object) is on left for app-data-table-cell,
//                                         numbers should be set to right and icons are on center

/*
                  For the last sticky cell, if greater with is needed, in parent component css you should add following:

                                        :host {
                                          --data-table-custom-last-sticky-cell-width: 150px;
                                        }
*/

import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  contentChildren,
  ElementRef,
  input,
  OnDestroy,
  output,
  TemplateRef,
  viewChild,
  ViewEncapsulation
} from '@angular/core';

import { NgScrollbar, ScrollbarPosition } from 'ngx-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NoDataDisclaimerComponent } from '../../molecules/no-data-disclaimer/no-data-disclaimer.component';
import { createRange } from '../../../utils';
import { DataTableCellDirective } from './data-table-cell/data-table-cell.directive';
import { DataTableColumn, DataTableDefaultConfig } from './data-table.model';
import { DataTableHeaderDirective } from './data-table-header.directive';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgScrollbar,
    NgClass,
    NgTemplateOutlet,
    NoDataDisclaimerComponent,
    TranslateModule
  ]
})
export class DataTableComponent
  implements AfterContentInit, AfterViewInit, OnDestroy
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly data = input<any[]>(undefined);
  readonly columns = input<DataTableColumn[]>(undefined);
  readonly sameBackgroundHeader = input(true);
  readonly hideHeader = input(false);
  readonly uniqueDataKey = input('id');

  readonly isLoading = input(DataTableDefaultConfig.isLoading);
  readonly withStickyLastCell = input(
    DataTableDefaultConfig.withStickyLastCell
  );
  readonly noDataHeader = input(DataTableDefaultConfig.noDataHeader);
  readonly noDataMessage = input(DataTableDefaultConfig.noDataMessage);
  readonly noDataShowImage = input(true);
  readonly removeBorderSpacing = input(true);
  readonly highlightKey = input<string>(undefined);
  readonly enableVariableScrollbar = input(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly rowClickEvent = output<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly scrollEvent = output();

  readonly templates = contentChildren(DataTableCellDirective);

  readonly headerTemplates = contentChildren(DataTableHeaderDirective);

  public baseClass = 'data-table';
  public skeletonRowsAmount = 3;
  public cellTemplatesMap: { [key: string]: TemplateRef<HTMLDivElement> };
  public headerTemplatesMap: { [key: string]: TemplateRef<HTMLDivElement> };
  public createRange = createRange;

  readonly tableAnchor = viewChild<ElementRef>('tableAnchor');
  private observer: IntersectionObserver;
  public scrollbarPosition: ScrollbarPosition = 'native';

  public ngAfterContentInit() {
    this.cellTemplatesMap = this.templates().reduce((acc, cur) => {
      acc[cur.name()] = cur.template;
      return acc;
    }, {});

    this.headerTemplatesMap = this.headerTemplates().reduce((acc, cur) => {
      acc[cur.name()] = cur.template;
      return acc;
    }, {});
  }

  public ngAfterViewInit(): void {
    /*
      If the bottom of the table is not visible (the table is scrolled down), the horizontal scrollbar is shown on top.
      Otherwise, the scrollbar is shown on the bottom (native behavior)
      This is needed so users can scroll the table horizontally without scrolling the whole page
      The functionality is enabled only when enableVariableScrollbar is set to true (default is false)
      */
    if (this.enableVariableScrollbar()) {
      this.observer = new IntersectionObserver(
        entries =>
          (this.scrollbarPosition = entries[0].isIntersecting
            ? 'native'
            : 'invertX'),
        {
          threshold: 0.2
        }
      );
      this.observer.observe(this.tableAnchor()?.nativeElement as HTMLElement);
    }
  }

  public ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  public onRowClick(event: Event): void {
    this.rowClickEvent.emit(event);
  }

  public onScroll(): void {
    this.scrollEvent.emit();
  }
}

import { Application } from '../application.model';
import { Contact } from '../contact.model';
import { AgentInfo } from '../landlord.model';
import { Property } from '../property.model';

export enum AppointmentInvitationState {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING'
}

export enum AppointmentAcceptanceCancelReason {
  OTHER_LANDLORD = 'OTHER_LANDLORD',
  OTHER_PROPERTY = 'OTHER_PROPERTY',
  PROPERTY_DOES_NOT_FIT = 'PROPERTY_DOES_NOT_FIT',
  PRIVATE_REASONS = 'PRIVATE_REASONS',
  APPOINTMENT_DOES_NOT_FIT = 'APPOINTMENT_DOES_NOT_FIT',
  OTHER_REASON = 'OTHER_REASON'
}

export interface Appointment {
  id?: string;
  propertyId?: string;
  date?: Date;
  created?: string;
  updated?: string;
  state?: AppointmentInvitationState;
  property?: Property;
  application?: string;
  propertyShortInfo?: Property;
  maxInviteeCount?: number;
  sizeOfAttendees?: number;
  showContactInformation?: boolean;
  creatorInfo?: AgentInfo;
  executorInfo?: AgentInfo;
  canceled?: boolean;
  contact?: Contact;
  full?: boolean;
  specialInstructions?: string;
  exclusiveAttendees?: AppointmentAttendee[];
  agentInfo?: AgentInfo;
  landlordUserSettings?: LandlordUserSettings;
}

export interface LandlordUserSettings {
  blockMinutesBeforeStart?: number;
}

export interface BlockAppointmentMinutesBeforeStart {
  blockAppointmentsMinutesBeforeStart?: number;
  storeBlockingDurationInUserSettings?: boolean;
}

export interface AppointmentAttendee {
  firstname?: string;
  lastname?: string;
  applicationId?: string;
  userProfileId?: string;
  state?: AppointmentInvitationState;
  score?: number;
  phoneNumber?: string;
}

export interface AppointmentAcceptance {
  id: string;
  state: string;
  application: Application;
  appointment: Appointment;
}

export interface SaveAppointmentPayload {
  appointments: Appointment[];
  blockAppointmentMinutesBeforeStartSetting?: BlockAppointmentMinutesBeforeStart;
  sendICSFile?: boolean;
}

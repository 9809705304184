import { Component, input } from '@angular/core';

import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelfDisclosureQuestion } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

import { defaultDocumentsConfig } from '../../../config';
import { AttachmentsComponent } from '../../../components/legacy/form/controls/attachment/attachments.component';
import { FormFieldComponent } from '../../../components/legacy/form/form-field/form-field.component';

@Component({
  selector: 'app-self-disclosure-document',
  templateUrl: './self-disclosure-document.component.html',
  styleUrls: ['./self-disclosure-document.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    TranslateModule,
    AttachmentsComponent,
    FormFieldComponent
  ]
})
export class SelfDisclosureDocumentComponent {
  readonly selfDisclosureQuestion = input<SelfDisclosureQuestion>(undefined);
  readonly form = input<FormGroup>(undefined);

  public acceptedDocumentsTypes = defaultDocumentsConfig.allAcceptedTypes;
  public documentsMaxSize = 1024 * 1024 * 20;

  public get getFormControl() {
    return this.form().get('answer') as FormGroup;
  }

  public get getUploadFormControl() {
    return this.form().get('upload') as FormGroup;
  }

  public get isDocumentValid() {
    return (
      !this.getUploadFormControl.valid && this.getUploadFormControl.touched
    );
  }
}
